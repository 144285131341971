export class Translation
{
	constructor(lang)
	{
		this._lang = 'en';

		if (typeof(lang) !== 'undefined')
		{
			this._lang = lang;
		}
		else if (typeof($('html').attr('lang')) !== 'undefined')
		{
			this._lang = $('html').attr('lang');
		}

		this._initializeLabels();
	}

	getLabel(code, placeholder, value)
	{
		if (typeof(this._labels[code]) !== 'undefined' && this._labels.hasOwnProperty(code))
		{
			if (typeof(placeholder) !== 'undefined' && typeof(value) !== 'undefined' && this._labels[code].indexOf(placeholder) > -1)
			{
				return this._labels[code].replace(placeholder, value);
			}

			return this._labels[code];
		}

		return '';
	}

	_initializeLabels()
	{
		switch (this._lang)
		{
			case 'de': // German
				this._initializeGermanLabels();

				break;
			case 'fr': // French
				this._initializeFrenchLabels();

				break;
			case 'it': // Italian
				this._initializeItalianLabels();

				break;
			case 'bg': // Bulgarian
				this._initializeBulgarianLabels();

				break;
			default: // English
				this._initializeEnglishLabels();
		}
	}

	_initializeEnglishLabels()
	{
		this._labels = {
			btnOK: 'OK',
			btnYes: 'Yes',
			btnNo: 'No',
			btnCancel: 'Cancel',
			btnConfirmRemove: 'Remove',
			optLanguage: 'en',
			optLanguageLong: 'en_EN',
			txtSuccessTitle: 'Success',
			txtErrorTitle: 'Error',
			txtInfoTitle: 'Information',
			txtWarningTitle: 'Warning',
			txtLoadingTitle: 'Loading...',
			txtLoadingMessage: 'Please be patient.',
			txtProcessingTitle: 'Processing',
			txtProcessingMessage: 'Please wait ...',
			txtConfirmTitle: 'Confirm remove',
			txtConfirmRemove: 'Are you sure you want to remove record #:id?',
			txtConfirmProceed: 'Are you sure you want to proceed?',
			txtDatabaseErrorMessage: 'Database error: ',
			txtSynchronizationTitle: 'Synchronization',
			txtSynchronizationMessage: 'Please wait for the process to finish.',
			txtNoSupportMessage: 'Offline requests are limited because there\'s no IndexedDB support.',
			txtValidationFailedMessage: 'One or more of the required fields are empty!',
			txtValidationInvalidPassword: 'Password should be at least 8 characters and should include:<ul><li>At least one number from 0 to 9</li><li>At least one uppercase letter (A-Z)</li><li>At least one lowercase letter (a-z)</li><li>At least one special character (!@#$% etc.)</li></ul>',
			txtValidationInvalidCode: 'Invalid intervention code!',
			txtValidationInvalidConsistency: 'Invalid datetime consistency!',
			txtExpireSoonMessage: 'Intervention validity will expire soon.',
			txtMaxLengthPre: 'You have ',
			txtMaxLengthPost: ' chars remaining.',
			txtMaxLengthSeparator: ' of ',
			txtSwitchConfirm: 'Are you sure?',
			txtTableEmpty: 'No data available',
			txtRequestFailedTitle: 'Error',
			txtRequestFailedMessage: 'Unable to proceed with the current request!',
			txtOfflineRestrictionMessage: 'Current action in unavailable in offline mode!',
			fiDropZoneClickTitle: '<br>(or click to select {files})',
			dtLanguage: {
				"decimal":        "",
				"emptyTable":     "No data available in table",
				"info":           "Showing _START_ to _END_ of _TOTAL_ entries",
				"infoEmpty":      "Showing 0 to 0 of 0 entries",
				"infoFiltered":   "(filtered from _MAX_ total entries)",
				"infoPostFix":    "",
				"thousands":      ",",
				"lengthMenu":     "Shown entries: _MENU_",
				"loadingRecords": "Loading...",
				"processing":     "Processing...",
				"search":         "Search:",
				"zeroRecords":    "No matching records found",
				"paginate": {
					"first":      '<i class="fal fa-angle-double-left"></i>',
					"last":       '<i class="fal fa-angle-double-right"></i>',
					"next":       '<i class="fal fa-angle-right"></i>',
					"previous":   '<i class="fal fa-angle-left"></i>'
				},
				"aria": {
					"sortAscending":  ": activate to sort column ascending",
					"sortDescending": ": activate to sort column descending"
				}
			},
			dtAll: 'All',
			dtButtonExport: 'Export',
			dtButtonCopy: 'Copy',
			dtButtonExcel: 'Excel',
			dtButtonPDF: 'PDF',
			dtButtonCSV: 'CSV',
			dtButtonPrint: 'Print',
			dtButtonColumns: 'Visible columns',
			dtButtonColVis: '<i class="fal fa-columns"></i>',
			drpRanges: {
				'Today': [moment(), moment()],
				'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
				'Last 48 Hours': [moment().subtract(1, 'days'), moment()],
				'Last 7 Days': [moment().subtract(6, 'days'), moment()],
				'Last 30 Days': [moment().subtract(29, 'days'), moment()],
				'This Month': [moment().startOf('month'), moment().endOf('month')],
				'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
			},
			drpLocale: {
				"format": "DD.MM.YYYY",
				"separator": " - ",
				"applyLabel": "Apply",
				"cancelLabel": "Cancel",
				"fromLabel": "From",
				"toLabel": "To",
				"customRangeLabel": "Custom",
				"weekLabel": "W",
				"daysOfWeek": [
					"Su",
					"Mo",
					"Tu",
					"We",
					"Th",
					"Fr",
					"Sa"
				],
				"monthNames": [
					"January",
					"February",
					"March",
					"April",
					"May",
					"June",
					"July",
					"August",
					"September",
					"October",
					"November",
					"December"
				],
				"firstDay": 1
			},
			bmsNonSelected: 'None selected',
			bmsManySelected: 'selected',
			bmsAllSelected: 'All selected',
		};
	}

	_initializeGermanLabels()
	{
		if ($.validator)
		{
			$.extend($.validator.messages, {
				required: "Dieses Feld ist ein Pflichtfeld.",
				maxlength: $.validator.format("Geben Sie bitte maximal {0} Zeichen ein."),
				minlength: $.validator.format("Geben Sie bitte mindestens {0} Zeichen ein."),
				rangelength: $.validator.format("Geben Sie bitte mindestens {0} und maximal {1} Zeichen ein."),
				email: "Geben Sie bitte eine gültige E-Mail-Adresse ein.",
				url: "Geben Sie bitte eine gültige URL ein.",
				date: "Geben Sie bitte ein gültiges Datum ein.",
				number: "Geben Sie bitte eine Nummer ein.",
				digits: "Geben Sie bitte nur Ziffern ein.",
				equalTo: "Wiederholen Sie bitte denselben Wert.",
				range: $.validator.format("Geben Sie bitte einen Wert zwischen {0} und {1} ein."),
				max: $.validator.format("Geben Sie bitte einen Wert kleiner oder gleich {0} ein."),
				min: $.validator.format("Geben Sie bitte einen Wert größer oder gleich {0} ein."),
				creditcard: "Geben Sie bitte eine gültige Kreditkarten-Nummer ein.",
				remote: "Korrigieren Sie bitte dieses Feld.",
				dateISO: "Geben Sie bitte ein gültiges Datum ein (ISO-Format).",
				step: $.validator.format("Geben Sie bitte ein Vielfaches von {0} ein."),
				maxWords: $.validator.format("Geben Sie bitte {0} Wörter oder weniger ein."),
				minWords: $.validator.format("Geben Sie bitte mindestens {0} Wörter ein."),
				rangeWords: $.validator.format("Geben Sie bitte zwischen {0} und {1} Wörtern ein."),
				accept: "Geben Sie bitte einen Wert mit einem gültigen MIME-Typ ein.",
				alphanumeric: "Geben Sie bitte nur Buchstaben (keine Umlaute), Zahlen oder Unterstriche ein.",
				bankaccountNL: "Geben Sie bitte eine gültige Kontonummer ein.",
				bankorgiroaccountNL: "Geben Sie bitte eine gültige Bank- oder Girokontonummer ein.",
				bic: "Geben Sie bitte einen gültigen BIC-Code ein.",
				cifES: "Geben Sie bitte eine gültige CIF-Nummer ein.",
				cpfBR: "Geben Sie bitte eine gültige CPF-Nummer ein.",
				creditcardtypes: "Geben Sie bitte eine gültige Kreditkarten-Nummer ein.",
				currency: "Geben Sie bitte eine gültige Währung ein.",
				extension: "Geben Sie bitte einen Wert mit einer gültigen Erweiterung ein.",
				giroaccountNL: "Geben Sie bitte eine gültige Girokontonummer ein.",
				iban: "Geben Sie bitte eine gültige IBAN ein.",
				integer:  "Geben Sie bitte eine positive oder negative Nicht-Dezimalzahl ein.",
				ipv4: "Geben Sie bitte eine gültige IPv4-Adresse ein.",
				ipv6: "Geben Sie bitte eine gültige IPv6-Adresse ein.",
				lettersonly: "Geben Sie bitte nur Buchstaben ein.",
				letterswithbasicpunc: "Geben Sie bitte nur Buchstaben oder Interpunktion ein.",
				mobileNL: "Geben Sie bitte eine gültige Handynummer ein.",
				mobileUK: "Geben Sie bitte eine gültige Handynummer ein.",
				netmask:  "Geben Sie bitte eine gültige Netzmaske ein.",
				nieES: "Geben Sie bitte eine gültige NIE-Nummer ein.",
				nifES: "Geben Sie bitte eine gültige NIF-Nummer ein.",
				nipPL: "Geben Sie bitte eine gültige NIP-Nummer ein.",
				notEqualTo: "Geben Sie bitte einen anderen Wert ein. Die Werte dürfen nicht gleich sein.",
				nowhitespace: "Kein Leerzeichen bitte.",
				pattern: "Ungültiges Format.",
				phoneNL: "Geben Sie bitte eine gültige Telefonnummer ein.",
				phonesUK: "Geben Sie bitte eine gültige britische Telefonnummer ein.",
				phoneUK: "Geben Sie bitte eine gültige Telefonnummer ein.",
				phoneUS: "Geben Sie bitte eine gültige Telefonnummer ein.",
				postalcodeBR: "Geben Sie bitte eine gültige brasilianische Postleitzahl ein.",
				postalCodeCA: "Geben Sie bitte eine gültige kanadische Postleitzahl ein.",
				postalcodeIT: "Geben Sie bitte eine gültige italienische Postleitzahl ein.",
				postalcodeNL: "Geben Sie bitte eine gültige niederländische Postleitzahl ein.",
				postcodeUK: "Geben Sie bitte eine gültige britische Postleitzahl ein.",
				require_from_group: $.validator.format("Füllen Sie bitte mindestens {0} dieser Felder aus."),
				skip_or_fill_minimum: $.validator.format("Überspringen Sie bitte diese Felder oder füllen Sie mindestens {0} von ihnen aus."),
				stateUS: "Geben Sie bitte einen gültigen US-Bundesstaat ein.",
				strippedminlength: $.validator.format("Geben Sie bitte mindestens {0} Zeichen ein."),
				time: "Geben Sie bitte eine gültige Uhrzeit zwischen 00:00 und 23:59 ein.",
				time12h: "Geben Sie bitte eine gültige Uhrzeit im 12-Stunden-Format ein.",
				vinUS: "Die angegebene Fahrzeugidentifikationsnummer (VIN) ist ungültig.",
				zipcodeUS: "Die angegebene US-Postleitzahl ist ungültig.",
				ziprange: "Ihre Postleitzahl muss im Bereich 902xx-xxxx bis 905xx-xxxx liegen."
			});
		}

		if ($.fn.pickadate)
		{
			$.extend($.fn.pickadate.defaults, {
				monthsFull: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
				monthsShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
				weekdaysFull: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
				weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
				today: 'Heute',
				clear: 'Löschen',
				close: 'Schließen',
				firstDay: 1,
				format: 'dddd, dd. mmmm yyyy',
				formatSubmit: 'yyyy-mm-dd'
			});
		}

		if ($.fn.pickatime)
		{
			$.extend($.fn.pickatime.defaults, {
				clear: 'Löschen',
				format: 'H:i'
			});
		}

		/*if ($.fn.multiselect)
		{
			$.extend($.fn.multiselect.Constructor.prototype.defaults, {
				allSelectedText: 'Alle ausgewählt',
				filterPlaceholder: 'Suche',
				nSelectedText: 'ausgewählt',
				nonSelectedText: 'Nichts ausgewählt',
				selectAllText: 'Wählen Sie Alle'
			});
		}*/

		if($.fn.fileinput)
		{
			$.fn.fileinputLocales['de'] = {
				fileSingle: 'Datei',
				filePlural: 'Dateien',
				browseLabel: 'Auswählen &hellip;',
				removeLabel: 'Löschen',
				removeTitle: 'Ausgewählte löschen',
				cancelLabel: 'Abbrechen',
				cancelTitle: 'Hochladen abbrechen',
				pauseLabel: 'Pause',
				pauseTitle: 'Pause ongoing upload',
				uploadLabel: 'Hochladen',
				uploadTitle: 'Hochladen der ausgewählten Dateien',
				msgNo: 'Keine',
				msgNoFilesSelected: 'Keine Dateien ausgewählt',
				msgPaused: 'Paused',
				msgCancelled: 'Abgebrochen',
				msgPlaceholder: '{files} auswählen ...',
				msgZoomModalHeading: 'ausführliche Vorschau',
				msgFileRequired: 'Sie müssen eine Datei zum Hochladen auswählen.',
				msgSizeTooSmall: 'Datei "{name}" (<b>{size} KB</b>) unterschreitet mindestens notwendige Upload-Größe von <b>{minSize} KB</b>.',
				msgSizeTooLarge: 'Datei "{name}" (<b>{size} KB</b>) überschreitet maximal zulässige Upload-Größe von <b>{maxSize} KB</b>.',
				msgFilesTooLess: 'Sie müssen mindestens <b>{n}</b> {files} zum Hochladen auswählen.',
				msgFilesTooMany: 'Anzahl der zum Hochladen ausgewählten Dateien <b>({n})</b>, überschreitet maximal zulässige Grenze von <b>{m}</b> Stück.',
				msgTotalFilesTooMany: 'You can upload a maximum of <b>{m}</b> files (<b>{n}</b> files detected).',
				msgFileNotFound: 'Datei "{name}" wurde nicht gefunden!',
				msgFileSecured: 'Sicherheitseinstellungen verhindern das Lesen der Datei "{name}".',
				msgFileNotReadable: 'Die Datei "{name}" ist nicht lesbar.',
				msgFilePreviewAborted: 'Dateivorschau abgebrochen für "{name}".',
				msgFilePreviewError: 'Beim Lesen der Datei "{name}" ein Fehler aufgetreten.',
				msgInvalidFileName: 'Ungültige oder nicht unterstützte Zeichen im Dateinamen "{name}".',
				msgInvalidFileType: 'Ungültiger Typ für Datei "{name}". Nur Dateien der Typen "{types}" werden unterstützt.',
				msgInvalidFileExtension: 'Ungültige Erweiterung für Datei "{name}". Nur Dateien mit der Endung "{extensions}" werden unterstützt.',
				msgFileTypes: {
					'image': 'image',
					'html': 'HTML',
					'text': 'text',
					'video': 'video',
					'audio': 'audio',
					'flash': 'flash',
					'pdf': 'PDF',
					'object': 'object'
				},
				msgUploadAborted: 'Der Datei-Upload wurde abgebrochen',
				msgUploadThreshold: 'Wird bearbeitet &hellip;',
				msgUploadBegin: 'Wird initialisiert &hellip;',
				msgUploadEnd: 'Erledigt',
				msgUploadResume: 'Resuming upload &hellip;',
				msgUploadEmpty: 'Keine gültigen Daten zum Hochladen verfügbar.',
				msgUploadError: 'Upload Error',
				msgDeleteError: 'Delete Error',
				msgProgressError: 'Fehler',
				msgValidationError: 'Validierungsfehler',
				msgLoading: 'Lade Datei {index} von {files} hoch &hellip;',
				msgProgress: 'Datei {index} von {files} - {name} - zu {percent}% fertiggestellt.',
				msgSelected: '{n} {files} ausgewählt',
				msgFoldersNotAllowed: 'Drag & Drop funktioniert nur bei Dateien! {n} Ordner übersprungen.',
				msgImageWidthSmall: 'Breite der Bilddatei "{name}" muss mindestens {size} px betragen.',
				msgImageHeightSmall: 'Höhe der Bilddatei "{name}" muss mindestens {size} px betragen.',
				msgImageWidthLarge: 'Breite der Bilddatei "{name}" nicht überschreiten {size} px.',
				msgImageHeightLarge: 'Höhe der Bilddatei "{name}" nicht überschreiten {size} px.',
				msgImageResizeError: 'Konnte nicht die Bildabmessungen zu ändern.',
				msgImageResizeException: 'Fehler beim Ändern der Größe des Bildes.<pre>{errors}</pre>',
				msgAjaxError: 'Bei der Aktion {operation} ist ein Fehler aufgetreten. Bitte versuche es später noch einmal!',
				msgAjaxProgressError: '{operation} fehlgeschlagen',
				msgDuplicateFile: 'File "{name}" of same size "{size} KB" has already been selected earlier. Skipping duplicate selection.',
				msgResumableUploadRetriesExceeded:  'Upload aborted beyond <b>{max}</b> retries for file <b>{file}</b>! Error Details: <pre>{error}</pre>',
				msgPendingTime: '{time} remaining',
				msgCalculatingTime: 'calculating time remaining',
				ajaxOperations: {
					deleteThumb: 'Datei löschen',
					uploadThumb: 'Datei hochladen',
					uploadBatch: 'Batch-Datei-Upload',
					uploadExtra: 'Formular-Datei-Upload'
				},
				dropZoneTitle: 'Dateien hierher ziehen &hellip;',
				dropZoneClickTitle: '<br>(oder klicken um {files} auszuwählen)',
				fileActionSettings: {
					removeTitle: 'Datei entfernen',
					uploadTitle: 'Datei hochladen',
					uploadRetryTitle: 'Upload erneut versuchen',
					downloadTitle: 'Datei herunterladen',
					zoomTitle: 'Details anzeigen',
					dragTitle: 'Verschieben / Neuordnen',
					indicatorNewTitle: 'Noch nicht hochgeladen',
					indicatorSuccessTitle: 'Hochgeladen',
					indicatorErrorTitle: 'Upload Fehler',
					indicatorPausedTitle: 'Upload Paused',
					indicatorLoadingTitle:  'Hochladen &hellip;'
				},
				previewZoomButtonTitles: {
					prev: 'Vorherige Datei anzeigen',
					next: 'Nächste Datei anzeigen',
					toggleheader: 'Header umschalten',
					fullscreen: 'Vollbildmodus umschalten',
					borderless: 'Randlosen Modus umschalten',
					close: 'Detailansicht schließen'
				}
			};
		}

		this._labels = {
			btnOK: 'OK',
			btnYes: 'Ja',
			btnNo: 'Nein',
			btnCancel: 'Stornieren',
			btnConfirmRemove: 'Entfernen',
			optLanguage: 'de',
			optLanguageLong: 'de_DE',
			txtSuccessTitle: 'Erfolg',
			txtErrorTitle: 'Error',
			txtInfoTitle: 'Information',
			txtWarningTitle: 'Warnung',
			txtLoadingTitle: 'Wird geladen...',
			txtLoadingMessage: 'Bitte haben Sie Geduld.',
			txtProcessingTitle: 'Wird bearbeitet',
			txtProcessingMessage: 'Warten Sie mal ...',
			txtConfirmTitle: 'Bestätigen Sie das Entfernen',
			txtConfirmRemove: 'Sind Sie sicher, dass Sie Datensatz #:id entfernen möchten?',
			txtConfirmProceed: 'Sind Sie sicher, dass Sie fortfahren möchten?',
			txtDatabaseErrorMessage: 'Datenbankfehler: ',
			txtSynchronizationTitle: 'Synchronisation',
			txtSynchronizationMessage: 'Bitte warten Sie, bis der Vorgang abgeschlossen ist.',
			txtNoSupportMessage: 'Offline-Anfragen sind begrenzt, da keine IndexedDB-Unterstützung vorhanden ist.',
			txtValidationFailedMessage: 'Ein oder mehrere der erforderlichen Felder sind leer!',
			txtValidationInvalidPassword: 'Das Passwort sollte mindestens 8 Zeichen lang sein und Folgendes enthalten:<ul><li>Mindestens eine Zahl von 0 bis 9</li><li>Mindestens einen Großbuchstaben (A-Z)</li><li>Mindestens einen Kleinbuchstabe (a-z)</li><li>Mindestens ein Sonderzeichen (!@#$% etc.)</li></ul>',
			txtValidationInvalidCode: 'Ungültiger Interventionscode!',
			txtValidationInvalidConsistency: 'Ungültige Konsistenz der Datumsangaben!',
			txtExpireSoonMessage: 'Intervention Gültigkeit wird in Kürze auslaufen.',
			txtMaxLengthPre: 'Du hast ',
			txtMaxLengthPost: ' verbleibende Zeichen.',
			txtMaxLengthSeparator: ' von ',
			txtSwitchConfirm: 'Bist du sicher?',
			txtTableEmpty: 'Keine Daten verfügbar',
			txtRequestFailedTitle: 'Error',
			txtRequestFailedMessage: 'Kann nicht mit der aktuellen Anforderung, um fortzufahren!',
			txtOfflineRestrictionMessage: 'Aktuelle Aktion im Offline-Modus nicht verfügbar!',
			fiDropZoneClickTitle: '<br>(oder klicken Sie auf {files} auszuwählen)',
			dtLanguage: {
				"emptyTable": "Keine Daten in der Tabelle vorhanden",
				"info": "_START_ bis _END_ von _TOTAL_ Einträgen",
				"infoEmpty": "Keine Daten vorhanden",
				"infoFiltered": "(gefiltert von _MAX_ Einträgen)",
				"infoThousands": ".",
				"loadingRecords": "Wird geladen ..",
				"processing": "Bitte warten ..",
				"paginate": {
					"first": "Erste",
					"previous": "Zurück",
					"next": "Nächste",
					"last": "Letzte"
				},
				"aria": {
					"sortAscending": ": aktivieren, um Spalte aufsteigend zu sortieren",
					"sortDescending": ": aktivieren, um Spalte absteigend zu sortieren"
				},
				"select": {
					"rows": {
						"_": "%d Zeilen ausgewählt",
						"1": "1 Zeile ausgewählt"
					},
					"1": "1 Eintrag ausgewählt",
					"2": "2 Einträge ausgewählt",
					"_": "%d Einträge ausgewählt",
					"cells": {
						"1": "1 Zelle ausgewählt",
						"_": "%d Zellen ausgewählt"
					},
					"columns": {
						"1": "1 Spalte ausgewählt",
						"_": "%d Spalten ausgewählt"
					}
				},
				"buttons": {
					"print": "Drucken",
					"copy": "Kopieren",
					"copyTitle": "In Zwischenablage kopieren",
					"copySuccess": {
						"_": "%d Zeilen kopiert",
						"1": "1 Zeile kopiert"
					},
					"collection": "Aktionen <span class=\"ui-button-icon-primary ui-icon ui-icon-triangle-1-s\"><\/span>",
					"colvis": "Spaltensichtbarkeit",
					"colvisRestore": "Sichtbarkeit wiederherstellen",
					"copyKeys": "Drücken Sie die Taste <i>ctrl<\/i> oder <i>⌘<\/i> + <i>C<\/i> um die Tabelle<br \/>in den Zwischenspeicher zu kopieren.<br \/><br \/>Um den Vorgang abzubrechen, klicken Sie die Nachricht an oder drücken Sie auf Escape.",
					"csv": "CSV",
					"excel": "Excel",
					"pageLength": {
						"-1": "Alle Zeilen anzeigen",
						"1": "Eine Zeile anzeigen",
						"_": "%d Zeilen anzeigen"
					},
					"pdf": "PDF"
				},
				"autoFill": {
					"cancel": "Abbrechen",
					"fill": "Alle Zellen mit <i>%d<i> füllen<\/i><\/i>",
					"fillHorizontal": "Alle horizontalen Zellen füllen",
					"fillVertical": "Alle vertikalen Zellen füllen",
					"info": "Bitte wählen Sie die gewünschte Aktion aus:"
				},
				"decimal": ",",
				"search": "Suche:",
				"searchBuilder": {
					"add": "Bedingung hinzufügen",
					"button": {
						"0": "Such-Baukasten",
						"_": "Such-Baukasten (%d)"
					},
					"condition": "Bedingung",
					"conditions": {
						"date": {
							"after": "Nach",
							"before": "Vor",
							"between": "Zwischen",
							"empty": "Leer",
							"not": "Nicht",
							"notBetween": "Nicht zwischen",
							"notEmpty": "Nicht leer",
							"equals": "Gleich"
						},
						"number": {
							"between": "Zwischen",
							"empty": "Leer",
							"equals": "Entspricht",
							"gt": "Größer als",
							"gte": "Größer als oder gleich",
							"lt": "Kleiner als",
							"lte": "Kleiner als oder gleich",
							"not": "Nicht",
							"notBetween": "Nicht zwischen",
							"notEmpty": "Nicht leer"
						},
						"string": {
							"contains": "Beinhaltet",
							"empty": "Leer",
							"endsWith": "Endet mit",
							"equals": "Entspricht",
							"not": "Nicht",
							"notEmpty": "Nicht leer",
							"startsWith": "Startet mit"
						},
						"array": {
							"equals": "ist gleich",
							"empty": "ist leer",
							"contains": "enthält",
							"not": "ist ungleich",
							"notEmpty": "ist nicht leer",
							"without": "aber nicht"
						}
					},
					"data": "Daten",
					"deleteTitle": "Filterregel entfernen",
					"leftTitle": "Äußere Kriterien",
					"logicAnd": "UND",
					"logicOr": "ODER",
					"rightTitle": "Innere Kriterien",
					"title": {
						"0": "Such-Baukasten",
						"_": "Such-Baukasten (%d)"
					},
					"value": "Wert",
					"clearAll": "Alle löschen"
				},
				"searchPanes": {
					"clearMessage": "Leeren",
					"collapse": {
						"0": "Suchmasken",
						"_": "Suchmasken (%d)"
					},
					"countFiltered": "{shown} ({total})",
					"emptyPanes": "Keine Suchmasken",
					"loadMessage": "Lade Suchmasken..",
					"title": "Aktive Filter: %d",
					"count": "Anzahl"
				},
				"searchPlaceholder": "Suchbegriff eingeben",
				"thousands": ".",
				"zeroRecords": "Keine passenden Einträge gefunden",
				"lengthMenu": "_MENU_ Zeilen anzeigen",
				"datetime": {
					"previous": "Vorher",
					"next": "Nachher",
					"hours": "Stunden",
					"minutes": "Minuten",
					"seconds": "Sekunden",
					"unknown": "Unbekannt"
				},
				"editor": {
					"close": "Schließen",
					"create": {
						"button": "Neu",
						"title": "Neuen Eintrag erstellen",
						"submit": "Neu"
					},
					"edit": {
						"button": "Ändern",
						"title": "Eintrag ändern",
						"submit": "ändern"
					},
					"remove": {
						"button": "Löschen",
						"title": "Löschen",
						"submit": "Löschen",
						"confirm": {
							"_": "Sollen %d Zeilen gelöscht werden?",
							"1": "Soll diese Zeile gelöscht werden?"
						}
					},
					"error": {
						"system": "Ein Systemfehler ist aufgetreten"
					},
					"multi": {
						"title": "Mehrere Werte",
						"info": "Die ausgewählten Elemente enthalten mehrere Werte für dieses Feld. Um alle Elemente für dieses Feld zu bearbeiten und auf denselben Wert zu setzen, klicken oder tippen Sie hier, andernfalls behalten diese ihre individuellen Werte bei.",
						"restore": "Änderungen zurücksetzen",
						"noMulti": "Dieses Feld kann nur einzeln bearbeitet werden, nicht als Teil einer Mengen-Änderung."
					}
				}
			},
			dtAll: 'Alle',
			dtButtonExport: 'Export',
			dtButtonCopy: 'Kopieren',
			dtButtonExcel: 'Excel',
			dtButtonPDF: 'PDF',
			dtButtonCSV: 'CSV',
			dtButtonPrint: 'Druck',
			dtButtonColumns: 'Sichtbare Spalten',
			dtButtonColVis: '<i class="fal fa-columns"></i>',
			drpRanges: {
				'Heute': [moment(), moment()],
				'Gestern': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
				'Letzten 48 Stunden': [moment().subtract(1, 'days'), moment()],
				'Letzten 7 Tage': [moment().subtract(6, 'days'), moment()],
				'Letzte 30 Tage': [moment().subtract(29, 'days'), moment()],
				'Diesen Monat': [moment().startOf('month'), moment().endOf('month')],
				'Letzten Monat': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
			},
			drpLocale: {
				"format": "DD.MM.YYYY",
				"separator": " - ",
				"applyLabel": "Anwenden",
				"cancelLabel": "Abbrechen",
				"fromLabel": "Von",
				"toLabel": "Zu",
				"customRangeLabel": "Benutzerdefiniert",
				"weekLabel": "W",
				"daysOfWeek": [
					'So',
					'Mo',
					'Di',
					'Mi',
					'Do',
					'Fr',
					'Sa'
				],
				"monthNames": [
					"Januar",
					"Februar",
					"März",
					"April",
					"Mai",
					"Juni",
					"Juli",
					"August",
					"September",
					"Oktober",
					"November",
					"Dezember"
				],
				"firstDay": 1
			},
			bmsNonSelected: 'Nichts ausgewählt',
			bmsManySelected: 'ausgewählt',
			bmsAllSelected: 'Alle ausgewählt',
		};
	}

	_initializeFrenchLabels()
	{
		if ($.validator)
		{
			$.extend($.validator.messages, {
				required: "Ce champ est obligatoire.",
				remote: "Veuillez corriger ce champ.",
				email: "Veuillez fournir une adresse électronique valide.",
				url: "Veuillez fournir une adresse URL valide.",
				date: "Veuillez fournir une date valide.",
				dateISO: "Veuillez fournir une date valide (ISO).",
				number: "Veuillez fournir un numéro valide.",
				digits: "Veuillez fournir seulement des chiffres.",
				creditcard: "Veuillez fournir un numéro de carte de crédit valide.",
				equalTo: "Veuillez fournir encore la même valeur.",
				notEqualTo: "Veuillez fournir une valeur différente, les valeurs ne doivent pas être identiques.",
				extension: "Veuillez fournir une valeur avec une extension valide.",
				maxlength: $.validator.format( "Veuillez fournir au plus {0} caractères." ),
				minlength: $.validator.format( "Veuillez fournir au moins {0} caractères." ),
				rangelength: $.validator.format( "Veuillez fournir une valeur qui contient entre {0} et {1} caractères." ),
				range: $.validator.format( "Veuillez fournir une valeur entre {0} et {1}." ),
				max: $.validator.format( "Veuillez fournir une valeur inférieure ou égale à {0}." ),
				min: $.validator.format( "Veuillez fournir une valeur supérieure ou égale à {0}." ),
				step: $.validator.format( "Veuillez fournir une valeur multiple de {0}." ),
				maxWords: $.validator.format( "Veuillez fournir au plus {0} mots." ),
				minWords: $.validator.format( "Veuillez fournir au moins {0} mots." ),
				rangeWords: $.validator.format( "Veuillez fournir entre {0} et {1} mots." ),
				letterswithbasicpunc: "Veuillez fournir seulement des lettres et des signes de ponctuation.",
				alphanumeric: "Veuillez fournir seulement des lettres, nombres, espaces et soulignages.",
				lettersonly: "Veuillez fournir seulement des lettres.",
				nowhitespace: "Veuillez ne pas inscrire d'espaces blancs.",
				ziprange: "Veuillez fournir un code postal entre 902xx-xxxx et 905-xx-xxxx.",
				integer: "Veuillez fournir un nombre non décimal qui est positif ou négatif.",
				vinUS: "Veuillez fournir un numéro d'identification du véhicule (VIN).",
				dateITA: "Veuillez fournir une date valide.",
				time: "Veuillez fournir une heure valide entre 00:00 et 23:59.",
				phoneUS: "Veuillez fournir un numéro de téléphone valide.",
				phoneUK: "Veuillez fournir un numéro de téléphone valide.",
				mobileUK: "Veuillez fournir un numéro de téléphone mobile valide.",
				strippedminlength: $.validator.format( "Veuillez fournir au moins {0} caractères." ),
				email2: "Veuillez fournir une adresse électronique valide.",
				url2: "Veuillez fournir une adresse URL valide.",
				creditcardtypes: "Veuillez fournir un numéro de carte de crédit valide.",
				ipv4: "Veuillez fournir une adresse IP v4 valide.",
				ipv6: "Veuillez fournir une adresse IP v6 valide.",
				require_from_group: $.validator.format( "Veuillez fournir au moins {0} de ces champs." ),
				nifES: "Veuillez fournir un numéro NIF valide.",
				nieES: "Veuillez fournir un numéro NIE valide.",
				cifES: "Veuillez fournir un numéro CIF valide.",
				postalCodeCA: "Veuillez fournir un code postal valide.",
				pattern: "Format non valide."
			});
		}

		if ($.fn.pickadate)
		{
			$.extend($.fn.pickadate.defaults, {
				monthsFull: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
				monthsShort: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'],
				weekdaysFull: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
				weekdaysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
				today: 'Aujourd\'hui',
				clear: 'Effacer',
				close: 'Fermer',
				firstDay: 1,
				format: 'dd mmmm yyyy',
				formatSubmit: 'yyyy-mm-dd',
				labelMonthNext:"Mois suivant",
				labelMonthPrev:"Mois précédent",
				labelMonthSelect:"Sélectionner un mois",
				labelYearSelect:"Sélectionner une année"
			});

		}

		if ($.fn.pickatime)
		{
			$.extend($.fn.pickatime.defaults, {
				clear: 'Effacer',
				format: 'H:i'
			});
		}

		/*if ($.fn.multiselect)
		{
			$.extend($.fn.multiselect.Constructor.prototype.defaults, {
				allSelectedText: 'Tous sélectionnés',
				filterPlaceholder: 'Rechercher',
				nSelectedText: 'sélectionné',
				nonSelectedText: 'Aucun sélectionné',
				selectAllText: 'Tout sélectionner'
			});
		}*/

		if($.fn.fileinput)
		{
			$.fn.fileinputLocales['fr'] = {
				fileSingle: 'fichier',
				filePlural: 'fichiers',
				browseLabel: 'Parcourir &hellip;',
				removeLabel: 'Retirer',
				removeTitle: 'Retirer les fichiers sélectionnés',
				cancelLabel: 'Annuler',
				cancelTitle: 'Annuler le transfert en cours',
				pauseLabel: 'Suspendre',
				pauseTitle: 'Suspendre le transfert en cours',
				uploadLabel: 'Transférer',
				uploadTitle: 'Transférer les fichiers sélectionnés',
				msgNo: 'Non',
				msgNoFilesSelected: 'Aucun fichier sélectionné',
				msgPaused: 'Suspendu',
				msgCancelled: 'Annulé',
				msgPlaceholder: 'Sélectionner le(s) {files} ...',
				msgZoomModalHeading: 'Aperçu détaillé',
				msgFileRequired: 'Vous devez sélectionner un fichier à envoyer.',
				msgSizeTooSmall: 'Le fichier "{name}" (<b>{size} KB</b>) est inférieur à la taille minimale de <b>{minSize} KB</b>.',
				msgSizeTooLarge: 'Le fichier "{name}" (<b>{size} Ko</b>) dépasse la taille maximale autorisée qui est de <b>{maxSize} Ko</b>.',
				msgFilesTooLess: 'Vous devez sélectionner au moins <b>{n}</b> {files} à transmettre.',
				msgFilesTooMany: 'Le nombre de fichiers sélectionnés <b>({n})</b> dépasse la quantité maximale autorisée qui est de <b>{m}</b>.',
				msgTotalFilesTooMany: 'Il n\'est pas permis d\'envoyer plus de <b>{m}</b> fichiers (actuellement <b>{n}</b> fichiers).',
				msgFileNotFound: 'Le fichier "{name}" est introuvable !',
				msgFileSecured: "Des restrictions de sécurité vous empêchent d'accéder au fichier \"{name}\".",
				msgFileNotReadable: 'Le fichier "{name}" est illisible.',
				msgFilePreviewAborted: 'Prévisualisation du fichier "{name}" annulée.',
				msgFilePreviewError: 'Une erreur est survenue lors de la lecture du fichier "{name}".',
				msgInvalidFileName: 'Caractères invalides ou non supportés dans le nom de fichier "{name}".',
				msgInvalidFileType: 'Type de document invalide pour "{name}". Seulement les documents de type "{types}" sont autorisés.',
				msgInvalidFileExtension: 'Extension invalide pour le fichier "{name}". Seules les extensions "{extensions}" sont autorisées.',
				msgFileTypes: {
					'image': 'image',
					'html': 'HTML',
					'text': 'text',
					'video': 'video',
					'audio': 'audio',
					'flash': 'flash',
					'pdf': 'PDF',
					'object': 'object'
				},
				msgUploadAborted: 'Le transfert du fichier a été interrompu',
				msgUploadThreshold: 'En cours &hellip;',
				msgUploadBegin: 'Initialisation &hellip;',
				msgUploadEnd: 'Terminé',
				msgUploadResume: 'Reprise du transfert &hellip;',
				msgUploadEmpty: 'Aucune donnée valide n\'est disponible pour l\'envoi.',
				msgUploadError: 'Erreur lors du transfert',
				msgDeleteError: 'Erreur de suppression',
				msgProgressError: 'Erreur',
				msgValidationError: 'Erreur de validation',
				msgLoading: 'Transmission du fichier {index} sur {files} &hellip;',
				msgProgress: 'Transmission du fichier {index} sur {files} - {name} - {percent}%.',
				msgSelected: '{n} {files} sélectionné(s)',
				msgFoldersNotAllowed: 'Glissez et déposez uniquement des fichiers ! {n} répertoire(s) exclu(s).',
				msgImageWidthSmall: 'La largeur de l\'image "{name}" doit être d\'au moins {size} px.',
				msgImageHeightSmall: 'La hauteur de l\'image "{name}" doit être d\'au moins {size} px.',
				msgImageWidthLarge: 'La largeur de l\'image "{name}" ne peut pas dépasser {size} px.',
				msgImageHeightLarge: 'La hauteur de l\'image "{name}" ne peut pas dépasser {size} px.',
				msgImageResizeError: "Impossible d'obtenir les dimensions de l'image à redimensionner.",
				msgImageResizeException: "Erreur lors du redimensionnement de l'image.<pre>{errors}</pre>",
				msgAjaxError: "Une erreur s'est produite pendant l'opération de {operation}. Veuillez réessayer plus tard.",
				msgAjaxProgressError: 'L\'opération "{operation}" a échouée',
				msgDuplicateFile: 'Le fichier "{name}" de taille "{size} KB" à déjà été envoyé. Les doublons sont ignorés.',
				msgResumableUploadRetriesExceeded:  'Le transfert a été annulé après <b>{max}</b> essais pour le fichier <b>{file}</b>! Details de l\'erreur: <pre>{error}</pre>',
				msgPendingTime: '{time} restant',
				msgCalculatingTime: 'calcul du temps restant',
				ajaxOperations: {
					deleteThumb: 'suppression du fichier',
					uploadThumb: 'transfert du fichier',
					uploadBatch: 'transfert des fichiers',
					uploadExtra: 'soumission des données du formulaire'
				},
				dropZoneTitle: 'Glissez et déposez les fichiers ici &hellip;',
				dropZoneClickTitle: '<br>(ou cliquez pour sélectionner manuellement)',
				fileActionSettings: {
					removeTitle: 'Supprimer le fichier',
					uploadTitle: 'Transférer le fichier',
					uploadRetryTitle: 'Relancer le transfert',
					downloadTitle: 'Télécharger',
					zoomTitle: 'Voir les détails',
					dragTitle: 'Déplacer / Réarranger',
					indicatorNewTitle: 'Pas encore transféré',
					indicatorSuccessTitle: 'Posté',
					indicatorErrorTitle: 'Ajouter erreur',
					indicatorPausedTitle: 'Transfert suspendu',
					indicatorLoadingTitle:  'En cours &hellip;'
				},
				previewZoomButtonTitles: {
					prev: 'Voir le fichier précédent',
					next: 'Voir le fichier suivant',
					toggleheader: 'Masquer le titre',
					fullscreen: 'Mode plein écran',
					borderless: 'Mode cinéma',
					close: "Fermer l'aperçu"
				}
			};
		}

		this._labels = {
			btnOK: 'OK',
			btnYes: 'Oui',
			btnNo: 'Non',
			btnCancel: 'Annuler',
			btnConfirmRemove: 'Supprimer',
			optLanguage: 'fr',
			optLanguageLong: 'fr_FR',
			txtSuccessTitle: 'Succès',
			txtErrorTitle: 'Erreur',
			txtInfoTitle: 'Informations',
			txtWarningTitle: 'Avertissement',
			txtLoadingTitle: 'Chargement...',
			txtLoadingMessage: 'Veuillez être patient.',
			txtProcessingTitle: 'Traitement',
			txtProcessingMessage: 'S\'il vous plaît, attendez ...',
			txtConfirmTitle: 'Confirmer la suppression',
			txtConfirmRemove: 'Voulez-vous vraiment supprimer l\'enregistrement #:id?',
			txtConfirmProceed: 'Êtes-vous sur de vouloir continuer?',
			txtDatabaseErrorMessage: 'Erreur de la base de données: ',
			txtSynchronizationTitle: 'Synchronisation',
			txtSynchronizationMessage: 'Veuillez attendre la fin du processus.',
			txtNoSupportMessage: 'Les demandes hors ligne sont limitées car il n\'y a pas de prise en charge d\'IndexedDB.',
			txtValidationFailedMessage: 'Un ou plusieurs des champs obligatoires sont vides!',
			txtValidationInvalidPassword: 'Le mot de passe doit comporter au moins 8 caractères et doit inclure:<ul><li>Au moins un chiffre de 0 à 9</li><li>Au moins une lettre majuscule (A-Z)</li><li>Au moins une lettre minuscule (a-z)</li><li>Au moins un caractère spécial (!@#$% etc.)</li></ul>',
			txtValidationInvalidCode: 'Code d\'intervention invalide!',
			txtValidationInvalidConsistency: 'Cohérence de la date invalide!',
			txtExpireSoonMessage: 'La validité de l\'intervention expirera bientôt.',
			txtMaxLengthPre: 'Vous avez ',
			txtMaxLengthPost: ' caractères restants.',
			txtMaxLengthSeparator: ' de ',
			txtSwitchConfirm: 'Êtes-vous sûr?',
			txtTableEmpty: 'Pas de données disponibles',
			txtRequestFailedTitle: 'Erreur',
			txtRequestFailedMessage: 'Impossible de traiter la demande en cours!',
			txtOfflineRestrictionMessage: 'L\'action en cours n\'est pas disponible en mode hors ligne!',
			fiDropZoneClickTitle: '<br>(ou cliquez pour sélectionner {files})',
			dtLanguage: {
				"emptyTable": "Aucune donnée disponible dans le tableau",
				"lengthMenu": "Afficher _MENU_ éléments",
				"loadingRecords": "Chargement...",
				"processing": "Traitement...",
				"zeroRecords": "Aucun élément correspondant trouvé",
				"paginate": {
					"first": "Premier",
					"last": "Dernier",
					"previous": "Précédent",
					"next": "Suiv"
				},
				"aria": {
					"sortAscending": ": activer pour trier la colonne par ordre croissant",
					"sortDescending": ": activer pour trier la colonne par ordre décroissant"
				},
				"select": {
					"rows": {
						"_": "%d lignes sélectionnées",
						"0": "Aucune ligne sélectionnée",
						"1": "1 ligne sélectionnée"
					},
					"1": "1 ligne selectionnée",
					"_": "%d lignes selectionnées",
					"cells": {
						"1": "1 cellule sélectionnée",
						"_": "%d cellules sélectionnées"
					},
					"columns": {
						"1": "1 colonne sélectionnée",
						"_": "%d colonnes sélectionnées"
					}
				},
				"autoFill": {
					"cancel": "Annuler",
					"fill": "Remplir toutes les cellules avec <i>%d<\/i>",
					"fillHorizontal": "Remplir les cellules horizontalement",
					"fillVertical": "Remplir les cellules verticalement",
					"info": "Exemple de remplissage automatique"
				},
				"searchBuilder": {
					"conditions": {
						"date": {
							"after": "Après le",
							"before": "Avant le",
							"between": "Entre",
							"empty": "Vide",
							"equals": "Egal à",
							"not": "Différent de",
							"notBetween": "Pas entre",
							"notEmpty": "Non vide"
						},
						"number": {
							"between": "Entre",
							"empty": "Vide",
							"equals": "Egal à",
							"gt": "Supérieur à",
							"gte": "Supérieur ou égal à",
							"lt": "Inférieur à",
							"lte": "Inférieur ou égal à",
							"not": "Différent de",
							"notBetween": "Pas entre",
							"notEmpty": "Non vide"
						},
						"string": {
							"contains": "Contient",
							"empty": "Vide",
							"endsWith": "Se termine par",
							"equals": "Egal à",
							"not": "Différent de",
							"notEmpty": "Non vide",
							"startsWith": "Commence par"
						},
						"array": {
							"equals": "Egal à",
							"empty": "Vide",
							"contains": "Contient",
							"not": "Différent de",
							"notEmpty": "Non vide",
							"without": "Sans"
						}
					},
					"add": "Ajouter une condition",
					"button": {
						"0": "Recherche avancée",
						"_": "Recherche avancée (%d)"
					},
					"clearAll": "Effacer tout",
					"condition": "Condition",
					"data": "Donnée",
					"deleteTitle": "Supprimer la règle de filtrage",
					"logicAnd": "Et",
					"logicOr": "Ou",
					"title": {
						"0": "Recherche avancée",
						"_": "Recherche avancée (%d)"
					},
					"value": "Valeur"
				},
				"searchPanes": {
					"clearMessage": "Effacer tout",
					"count": "{total}",
					"title": "Filtres actifs - %d",
					"collapse": {
						"0": "Volet de recherche",
						"_": "Volet de recherche (%d)"
					},
					"countFiltered": "{shown} ({total})",
					"emptyPanes": "Pas de volet de recherche",
					"loadMessage": "Chargement du volet de recherche..."
				},
				"buttons": {
					"copyKeys": "Appuyer sur ctrl ou u2318 + C pour copier les données du tableau dans votre presse-papier.",
					"collection": "Collection",
					"colvis": "Visibilité colonnes",
					"colvisRestore": "Rétablir visibilité",
					"copy": "Copier",
					"copySuccess": {
						"1": "1 ligne copiée dans le presse-papier",
						"_": "%ds lignes copiées dans le presse-papier"
					},
					"copyTitle": "Copier dans le presse-papier",
					"csv": "CSV",
					"excel": "Excel",
					"pageLength": {
						"-1": "Afficher toutes les lignes",
						"1": "Afficher 1 ligne",
						"_": "Afficher %d lignes"
					},
					"pdf": "PDF",
					"print": "Imprimer"
				},
				"decimal": ",",
				"info": "Affichage de _START_ à _END_ sur _TOTAL_ éléments",
				"infoEmpty": "Affichage de 0 à 0 sur 0 éléments",
				"infoThousands": ".",
				"search": "Rechercher:",
				"searchPlaceholder": "...",
				"thousands": ".",
				"infoFiltered": "(filtrés depuis un total de _MAX_ éléments)",
				"datetime": {
					"previous": "Précédent",
					"next": "Suivant",
					"hours": "Heures",
					"minutes": "Minutes",
					"seconds": "Secondes",
					"unknown": "-",
					"amPm": [
						"am",
						"pm"
					]
				},
				"editor": {
					"close": "Fermer",
					"create": {
						"button": "Nouveaux",
						"title": "Créer une nouvelle entrée",
						"submit": "Envoyer"
					},
					"edit": {
						"button": "Editer",
						"title": "Editer Entrée",
						"submit": "Modifier"
					},
					"remove": {
						"button": "Supprimer",
						"title": "Supprimer",
						"submit": "Supprimer"
					},
					"error": {
						"system": "Une erreur système s'est produite"
					},
					"multi": {
						"title": "Valeurs Multiples",
						"restore": "Rétablir Modification"
					}
				}
			},
			dtAll: 'Tout',
			dtButtonExport: 'Exportation',
			dtButtonCopy: 'Copie',
			dtButtonExcel: 'Excel',
			dtButtonPDF: 'PDF',
			dtButtonCSV: 'CSV',
			dtButtonPrint: 'Imprimer',
			dtButtonColumns: 'Colonnes visibles',
			dtButtonColVis: '<i class="fal fa-columns"></i>',
			drpRanges: {
				'Aujourd\'hui': [moment(), moment()],
				'Hier': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
				'Dernières 48 heures': [moment().subtract(1, 'days'), moment()],
				'Les 7 derniers jours': [moment().subtract(6, 'days'), moment()],
				'Les 30 derniers jours': [moment().subtract(29, 'days'), moment()],
				'Ce mois-ci': [moment().startOf('month'), moment().endOf('month')],
				'Le mois dernier': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
			},
			drpLocale: {
				"format": "DD.MM.YYYY",
				"separator": " - ",
				"applyLabel": "Mettre",
				"cancelLabel": "Annuler",
				"fromLabel": "De",
				"toLabel": "À",
				"customRangeLabel": "Personnalisé",
				"weekLabel": "S",
				"daysOfWeek": [
					"Dim",
					"Lun",
					"Mar",
					"Mer",
					"Jeu",
					"Ven",
					"Sam"
				],
				"monthNames": [
					"Janvier",
					"Février",
					"Mars",
					"Avril",
					"Mai",
					"Juin",
					"Juillet",
					"Août",
					"Septembre",
					"Octobre",
					"Novembre",
					"Décembre"
				],
				"firstDay": 1
			},
			bmsNonSelected: 'Aucun sélectionné',
			bmsManySelected: 'sélectionné',
			bmsAllSelected: 'Tous sélectionnés',
		};
	}

	_initializeItalianLabels()
	{
		if ($.validator)
		{
			$.extend($.validator.messages, {
				required: "Campo obbligatorio",
				remote: "Controlla questo campo",
				email: "Inserisci un indirizzo email valido",
				url: "Inserisci un indirizzo web valido",
				date: "Inserisci una data valida",
				dateISO: "Inserisci una data valida (ISO)",
				number: "Inserisci un numero valido",
				digits: "Inserisci solo numeri",
				creditcard: "Inserisci un numero di carta di credito valido",
				equalTo: "Il valore non corrisponde",
				extension: "Inserisci un valore con un&apos;estensione valida",
				maxlength: $.validator.format( "Non inserire pi&ugrave; di {0} caratteri" ),
				minlength: $.validator.format( "Inserisci almeno {0} caratteri" ),
				rangelength: $.validator.format( "Inserisci un valore compreso tra {0} e {1} caratteri" ),
				range: $.validator.format( "Inserisci un valore compreso tra {0} e {1}" ),
				max: $.validator.format( "Inserisci un valore minore o uguale a {0}" ),
				min: $.validator.format( "Inserisci un valore maggiore o uguale a {0}" ),
				nifES: "Inserisci un NIF valido",
				nieES: "Inserisci un NIE valido",
				cifES: "Inserisci un CIF valido",
				currency: "Inserisci una valuta valida"
			});
		}

		if ($.fn.pickadate)
		{
			$.extend($.fn.pickadate.defaults, {
				monthsFull: ['gennaio', 'febbraio', 'marzo', 'aprile', 'maggio', 'giugno', 'luglio', 'agosto', 'settembre', 'ottobre', 'novembre', 'dicembre'],
				monthsShort: ['gen', 'feb', 'mar', 'apr', 'mag', 'giu', 'lug', 'ago', 'set', 'ott', 'nov', 'dic'],
				weekdaysFull: ['domenica', 'lunedì', 'martedì', 'mercoledì', 'giovedì', 'venerdì', 'sabato'],
				weekdaysShort: ['dom', 'lun', 'mar', 'mer', 'gio', 'ven', 'sab'],
				today: 'Oggi',
				clear: 'Cancella',
				close: 'Chiudi',
				firstDay: 1,
				format: 'dddd d mmmm yyyy',
				formatSubmit: 'yyyy-mm-dd',
				labelMonthNext: 'Mese successivo',
				labelMonthPrev: 'Mese precedente',
				labelMonthSelect: 'Seleziona un mese',
				labelYearSelect: 'Seleziona un anno'
			});
		}

		if ($.fn.pickatime)
		{
			$.extend($.fn.pickatime.defaults, {
				clear: 'Cancella',
				format: 'HH:i'
			});
		}

		/*if ($.fn.multiselect)
		{
			$.extend($.fn.multiselect.Constructor.prototype.defaults, {
				allSelectedText: 'Tutti selezionati',
				filterPlaceholder: 'Ricerca',
				nSelectedText: 'selezionato',
				nonSelectedText: 'Nessuno selezionato',
				selectAllText: 'Seleziona tutto'
			});
		}*/

		if($.fn.fileinput)
		{
			$.fn.fileinputLocales['it'] = {
				fileSingle: 'file',
				filePlural: 'file',
				browseLabel: 'Sfoglia &hellip;',
				removeLabel: 'Rimuovi',
				removeTitle: 'Rimuovi i file selezionati',
				cancelLabel: 'Annulla',
				cancelTitle: 'Annulla i caricamenti in corso',
				pauseLabel: 'Pause',
				pauseTitle: 'Pause ongoing upload',
				uploadLabel: 'Carica',
				uploadTitle: 'Carica i file selezionati',
				msgNo: 'No',
				msgNoFilesSelected: 'Nessun file selezionato',
				msgPaused: 'Paused',
				msgCancelled: 'Annullato',
				msgPlaceholder: 'Seleziona {files} ...',
				msgZoomModalHeading: 'Anteprima dettagliata',
				msgFileRequired: 'Devi selezionare un file da caricare.',
				msgSizeTooSmall: 'Il file "{name}" (<b>{size} KB</b>) è troppo piccolo, deve essere almeno di <b>{minSize} KB</b>.',
				msgSizeTooLarge: 'Il file "{name}" (<b>{size} KB</b>) eccede la dimensione massima di caricamento di <b>{maxSize} KB</b>.',
				msgFilesTooLess: 'Devi selezionare almeno <b>{n}</b> {files} da caricare.',
				msgFilesTooMany: 'Il numero di file selezionati per il caricamento <b>({n})</b> eccede il numero massimo di file accettati <b>{m}</b>.',
				msgTotalFilesTooMany: 'You can upload a maximum of <b>{m}</b> files (<b>{n}</b> files detected).',
				msgFileNotFound: 'File "{name}" non trovato!',
				msgFileSecured: 'Restrizioni di sicurezza impediscono la lettura del file "{name}".',
				msgFileNotReadable: 'Il file "{name}" non è leggibile.',
				msgFilePreviewAborted: 'Generazione anteprima per "{name}" annullata.',
				msgFilePreviewError: 'Errore durante la lettura del file "{name}".',
				msgInvalidFileName: 'Carattere non valido o non supportato nel file "{name}".',
				msgInvalidFileType: 'Tipo non valido per il file "{name}". Sono ammessi solo file di tipo "{types}".',
				msgInvalidFileExtension: 'Estensione non valida per il file "{name}". Sono ammessi solo file con estensione "{extensions}".',
				msgFileTypes: {
					'image': 'image',
					'html': 'HTML',
					'text': 'text',
					'video': 'video',
					'audio': 'audio',
					'flash': 'flash',
					'pdf': 'PDF',
					'object': 'object'
				},
				msgUploadAborted: 'Il caricamento del file è stato interrotto',
				msgUploadThreshold: 'In lavorazione &hellip;',
				msgUploadBegin: 'Inizializzazione &hellip;',
				msgUploadEnd: 'Fatto',
				msgUploadResume: 'Resuming upload &hellip;',
				msgUploadEmpty: 'Dati non disponibili',
				msgUploadError: 'Upload Error',
				msgDeleteError: 'Delete Error',
				msgProgressError: 'Errore',
				msgValidationError: 'Errore di convalida',
				msgLoading: 'Caricamento file {index} di {files} &hellip;',
				msgProgress: 'Caricamento file {index} di {files} - {name} - {percent}% completato.',
				msgSelected: '{n} {files} selezionati',
				msgFoldersNotAllowed: 'Trascina solo file! Ignorata/e {n} cartella/e.',
				msgImageWidthSmall: 'La larghezza dell\'immagine "{name}" deve essere di almeno {size} px.',
				msgImageHeightSmall: 'L\'altezza dell\'immagine "{name}" deve essere di almeno {size} px.',
				msgImageWidthLarge: 'La larghezza dell\'immagine "{name}" non può superare {size} px.',
				msgImageHeightLarge: 'L\'altezza dell\'immagine "{name}" non può superare {size} px.',
				msgImageResizeError: 'Impossibile ottenere le dimensioni dell\'immagine per ridimensionare.',
				msgImageResizeException: 'Errore durante il ridimensionamento dell\'immagine.<pre>{errors}</pre>',
				msgAjaxError: 'Qualcosa non ha funzionato con l\'operazione {operation}. Per favore riprova più tardi!',
				msgAjaxProgressError: '{operation} failed',
				msgDuplicateFile: 'File "{name}" of same size "{size} KB" has already been selected earlier. Skipping duplicate selection.',
				msgResumableUploadRetriesExceeded:  'Upload aborted beyond <b>{max}</b> retries for file <b>{file}</b>! Error Details: <pre>{error}</pre>',
				msgPendingTime: '{time} remaining',
				msgCalculatingTime: 'calculating time remaining',
				ajaxOperations: {
					deleteThumb: 'eliminazione file',
					uploadThumb: 'caricamento file',
					uploadBatch: 'caricamento file in batch',
					uploadExtra: 'upload dati del form'
				},
				dropZoneTitle: 'Trascina i file qui &hellip;',
				dropZoneClickTitle: '<br>(o clicca per selezionare {files})',
				fileActionSettings: {
					removeTitle: 'Rimuovere il file',
					uploadTitle: 'Caricare un file',
					uploadRetryTitle: 'Riprova il caricamento',
					downloadTitle: 'Scarica file',
					zoomTitle: 'Guarda i dettagli',
					dragTitle: 'Muovi / Riordina',
					indicatorNewTitle: 'Non ancora caricato',
					indicatorSuccessTitle: 'Caricati',
					indicatorErrorTitle: 'Carica Errore',
					indicatorPausedTitle: 'Upload Paused',
					indicatorLoadingTitle:  'Caricamento &hellip;'
				},
				previewZoomButtonTitles: {
					prev: 'Vedi il file precedente',
					next: 'Vedi il file seguente',
					toggleheader: 'Attiva header',
					fullscreen: 'Attiva full screen',
					borderless: 'Abilita modalità senza bordi',
					close: 'Chiudi'
				}
			};
		}

		this._labels = {
			btnOK: 'OK',
			btnYes: 'Sì',
			btnNo: 'No',
			btnCancel: 'Annulla',
			btnConfirmRemove: 'Rimuovere',
			optLanguage: 'it',
			optLanguageLong: 'it_IT',
			txtSuccessTitle: 'Successo',
			txtErrorTitle: 'Errore',
			txtInfoTitle: 'Informazione',
			txtWarningTitle: 'Avvertimento',
			txtLoadingTitle: 'Caricamento in corso...',
			txtLoadingMessage: 'Per favore sii paziente.',
			txtProcessingTitle: 'In lavorazione',
			txtProcessingMessage: 'Attendere prego ...',
			txtConfirmTitle: 'Conferma rimozione',
			txtConfirmRemove: 'Sei sicuro di voler rimuovere il record #:id?',
			txtConfirmProceed: 'Sei sicuro di voler procedere?',
			txtDatabaseErrorMessage: 'Errore di Database: ',
			txtSynchronizationTitle: 'Sincronizzazione',
			txtSynchronizationMessage: 'Attendi il completamento del processo.',
			txtNoSupportMessage: 'Le richieste offline sono limitate perché non c\'è supporto IndexedDB.',
			txtValidationFailedMessage: 'Uno o più campi obbligatori sono vuoti!',
			txtValidationInvalidPassword: 'La password deve essere di almeno 8 caratteri e deve includere:<ul><li>Almeno un numero da 0 a 9</li><li>Almeno una lettera maiuscola (A-Z)</li><li>Almeno una lettera minuscola (a-z)</li><li>Almeno un carattere speciale (!@#$% ecc.)</li></ul>',
			txtValidationInvalidCode: 'Codice di intervento non valido!',
			txtValidationInvalidConsistency: 'Coerenza data non valida!',
			txtExpireSoonMessage: 'La validità dell\'intervento scadrà a breve.',
			txtMaxLengthPre: 'Avete ',
			txtMaxLengthPost: ' caratteri rimanenti.',
			txtMaxLengthSeparator: ' di ',
			txtSwitchConfirm: 'Sei sicuro?',
			txtTableEmpty: 'Nessun dato disponibile',
			txtRequestFailedTitle: 'Errore',
			txtRequestFailedMessage: 'Impossibile procedere con la richiesta corrente!',
			txtOfflineRestrictionMessage: 'L\'azione corrente non è disponibile in modalità offline!',
			fiDropZoneClickTitle: '<br>(oppure fai clic per selezionare {files})',
			dtLanguage: {
				"infoFiltered": "(filtrati da _MAX_ elementi totali)",
				"infoThousands": ".",
				"loadingRecords": "Caricamento...",
				"processing": "Elaborazione...",
				"search": "Cerca:",
				"paginate": {
					"first": "Inizio",
					"previous": "Precedente",
					"next": "Successivo",
					"last": "Fine"
				},
				"aria": {
					"sortAscending": ": attiva per ordinare la colonna in ordine crescente",
					"sortDescending": ": attiva per ordinare la colonna in ordine decrescente"
				},
				"autoFill": {
					"cancel": "Annulla",
					"fill": "Riempi tutte le celle con <i>%d<\/i>",
					"fillHorizontal": "Riempi celle orizzontalmente",
					"fillVertical": "Riempi celle verticalmente",
					"info": "Informazioni"
				},
				"buttons": {
					"collection": "Collezione <span class=\"ui-button-icon-primary ui-icon ui-icon-triangle-1-s\"><\/span>",
					"colvis": "Visibilità Colonna",
					"colvisRestore": "Ripristina visibilità",
					"copy": "Copia",
					"copyKeys": "Premi ctrl o u2318 + C per copiare i dati della tabella nella tua clipboard di sistema.<br \/><br \/>Per annullare, clicca questo messaggio o premi ESC.",
					"copySuccess": {
						"1": "Copiata 1 riga nella clipboard",
						"_": "Copiate %d righe nella clipboard"
					},
					"copyTitle": "Copia nella Clipboard",
					"csv": "CSV",
					"excel": "Excel",
					"pageLength": {
						"-1": "Mostra tutte le righe",
						"1": "Mostra 1 riga",
						"_": "Mostra %d righe"
					},
					"pdf": "PDF",
					"print": "Stampa"
				},
				"decimal": ",",
				"emptyTable": "Nessun dato disponibile nella tabella",
				"info": "Risultati da _START_ a _END_ di _TOTAL_ elementi",
				"infoEmpty": "Risultati da 0 a 0 di 0 elementi",
				"infoPostFix": "",
				"lengthMenu": "Mostra _MENU_ elementi",
				"searchBuilder": {
					"add": "Aggiungi Condizione",
					"button": {
						"0": "Generatore di Ricerca",
						"_": "Generatori di Ricerca (%d)"
					},
					"clearAll": "Pulisci Tutto",
					"condition": "Condizione",
					"conditions": {
						"date": {
							"after": "Dopo",
							"before": "Prima",
							"between": "Tra",
							"empty": "Vuoto",
							"equals": "Uguale A",
							"not": "Non",
							"notBetween": "Non Tra",
							"notEmpty": "Non Vuoto"
						},
						"number": {
							"between": "Tra",
							"empty": "Vuoto",
							"equals": "Uguale A",
							"gt": "Maggiore Di",
							"gte": "Maggiore O Uguale A",
							"lt": "Minore Di",
							"lte": "Minore O Uguale A",
							"not": "Non",
							"notBetween": "Non Tra",
							"notEmpty": "Non Vuoto"
						},
						"string": {
							"contains": "Contiene",
							"empty": "Vuoto",
							"endsWith": "Finisce Con",
							"equals": "Uguale A",
							"not": "Non",
							"notEmpty": "Non Vuoto",
							"startsWith": "Inizia Con"
						},
						"array": {
							"equals": "Uguale A",
							"empty": "Vuoto",
							"contains": "Contiene",
							"not": "Non",
							"notEmpty": "Non Vuoto",
							"without": "Senza"
						}
					},
					"data": "Dati",
					"deleteTitle": "Elimina regola filtro",
					"leftTitle": "Criterio di Riduzione Rientro",
					"logicAnd": "E",
					"logicOr": "O",
					"rightTitle": "Criterio di Aumento Rientro",
					"title": {
						"0": "Generatore di Ricerca",
						"_": "Generatori di Ricerca (%d)"
					},
					"value": "Valore"
				},
				"searchPanes": {
					"clearMessage": "Pulisci Tutto",
					"collapse": {
						"0": "Pannello di Ricerca",
						"_": "Pannelli di Ricerca (%d)"
					},
					"count": "{total}",
					"countFiltered": "{shown} ({total})",
					"emptyPanes": "Nessun Pannello di Ricerca",
					"loadMessage": "Caricamento Pannello di Ricerca",
					"title": "Filtri Attivi - %d"
				},
				"select": {
					"1": "%d riga selezionata",
					"_": "%d righe selezionate",
					"cells": {
						"1": "1 cella selezionata",
						"_": "%d celle selezionate"
					},
					"columns": {
						"1": "1 colonna selezionata",
						"_": "%d colonne selezionate"
					},
					"rows": {
						"1": "1 riga selezionata",
						"_": "%d righe selezionate",
						"0": "%d righe selezionate"
					},
					"0": "Nessuna riga selezionata",
					"2": "%d righe selezionate"
				},
				"zeroRecords": "Nessun elemento corrispondente trovato",
				"datetime": {
					"amPm": [
						"am",
						"pm"
					],
					"hours": "ore",
					"minutes": "minuti",
					"next": "successivo",
					"previous": "precedente",
					"seconds": "secondi",
					"unknown": "sconosciuto"
				},
				"editor": {
					"close": "Chiudi",
					"create": {
						"button": "Nuovo",
						"submit": "Aggiungi",
						"title": "Aggiungi nuovo elemento"
					},
					"edit": {
						"button": "Modifica",
						"submit": "Modifica",
						"title": "Modifica elemento"
					},
					"error": {
						"system": "Errore del sistema."
					},
					"multi": {
						"info": "Gli elementi selezionati contengono valori diversi. Per modificare e impostare tutti gli elementi per questa selezione allo stesso valore, premi o clicca qui, altrimenti ogni cella manterrà il suo valore attuale.",
						"noMulti": "Questa selezione può essere modificata individualmente, ma non se fa parte di un gruppo.",
						"restore": "Annulla le modifiche",
						"title": "Valori multipli"
					},
					"remove": {
						"button": "Rimuovi",
						"confirm": {
							"_": "Sei sicuro di voler cancellare %d righe?",
							"1": "Sei sicuro di voler cancellare 1 riga?"
						},
						"submit": "Rimuovi",
						"title": "Rimuovi"
					}
				},
				"searchPlaceholder": "Valore di esempio",
				"thousands": "."
			},
			dtAll: 'Tutti',
			dtButtonExport: 'Esportare',
			dtButtonCopy: 'Copia',
			dtButtonExcel: 'Excel',
			dtButtonPDF: 'PDF',
			dtButtonCSV: 'CSV',
			dtButtonPrint: 'Stampa',
			dtButtonColumns: 'Colonne visibili',
			dtButtonColVis: '<i class="fal fa-columns"></i>',
			drpRanges: {
				'Oggi': [moment(), moment()],
				'Ieri': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
				'Ultime 48 ore': [moment().subtract(1, 'days'), moment()],
				'Ultimi 7 giorni': [moment().subtract(6, 'days'), moment()],
				'Ultimi 30 giorni': [moment().subtract(29, 'days'), moment()],
				'Questo mese': [moment().startOf('month'), moment().endOf('month')],
				'Lo scorso mese': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
			},
			drpLocale: {
				"format": "DD.MM.YYYY",
				"separator": " - ",
				"applyLabel": "Applicare",
				"cancelLabel": "Annulla",
				"fromLabel": "Da",
				"toLabel": "A",
				"customRangeLabel": "Personalizzato",
				"weekLabel": "S",
				"daysOfWeek": [
					"dom",
					"lun",
					"mar",
					"mer",
					"gio",
					"ven",
					"sab"
				],
				"monthNames": [
					"Gennaio",
					"Febbraio",
					"Marzo",
					"Aprile",
					"Maggio",
					"Giugno",
					"Luglio",
					"Agosto",
					"Settembre",
					"Ottobre",
					"Novembre",
					"Dicembre"
				],
				"firstDay": 1
			},
			bmsNonSelected: 'Nessuno selezionato',
			bmsManySelected: 'selezionato',
			bmsAllSelected: 'Tutti selezionati',
		};
	}

	_initializeBulgarianLabels()
	{
		if ($.validator)
		{
			$.extend($.validator.messages, {
				required: "Полето е задължително.",
				remote: "Моля, въведете правилната стойност.",
				email: "Моля, въведете валиден email.",
				url: "Моля, въведете валидно URL.",
				date: "Моля, въведете валидна дата.",
				dateISO: "Моля, въведете валидна дата (ISO).",
				number: "Моля, въведете валиден номер.",
				digits: "Моля, въведете само цифри",
				creditcard: "Моля, въведете валиден номер на кредитна карта.",
				equalTo: "Моля, въведете същата стойност отново.",
				accept: "Моля, въведете стойност с валидно разширение.",
				maxlength: $.validator.format("Моля, въведете повече от {0} символа."),
				minlength: $.validator.format("Моля, въведете поне {0} символа."),
				minWords: $.validator.format("Моля, въведете поне {0} думи."),
				rangelength: $.validator.format("Моля, въведете стойност с дължина между {0} и {1} символа."),
				range: $.validator.format("Моля, въведете стойност между {0} и {1}."),
				max: $.validator.format("Моля, въведете стойност по-малка или равна на {0}."),
				min: $.validator.format("Моля, въведете стойност по-голяма или равна на {0}.")
			});
		}

		if ($.fn.pickadate)
		{
			$.extend($.fn.pickadate.defaults, {
				monthsFull: ['януари','февруари','март','април','май','юни','юли','август','септември','октомври','ноември','декември'],
				monthsShort: ['янр','фев','мар','апр','май','юни','юли','авг','сеп','окт','ное','дек'],
				weekdaysFull: ['неделя', 'понеделник', 'вторник', 'сряда', 'четвъртък', 'петък', 'събота'],
				weekdaysShort: ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
				today: 'днес',
				clear: 'изтривам',
				firstDay: 1,
				format: 'd mmmm yyyy г.',
				formatSubmit: 'yyyy-mm-dd'
			});
		}

		if ($.fn.pickatime)
		{
			$.extend($.fn.pickatime.defaults, {
				clear: 'изтривам'
			});
		}

		/*if ($.fn.multiselect)
		{
			$.extend($.fn.multiselect.Constructor.prototype.defaults, {
				allSelectedText: 'Всички избрани',
				filterPlaceholder: 'Търсене',
				nSelectedText: 'избрани',
				nonSelectedText: 'Няма избрани',
				selectAllText: 'Избери всички'
			});
		}*/

		if($.fn.fileinput)
		{
			$.fn.fileinputLocales['bg'] = {
				fileSingle: 'файл',
				filePlural: 'файла',
				browseLabel: 'Избери &hellip;',
				removeLabel: 'Премахни',
				removeTitle: 'Изчисти избраните',
				cancelLabel: 'Откажи',
				cancelTitle: 'Откажи качването',
				pauseLabel: 'Pause',
				pauseTitle: 'Pause ongoing upload',
				uploadLabel: 'Качи',
				uploadTitle: 'Качи избраните файлове',
				msgNo: 'Не',
				msgNoFilesSelected: '',
				msgPaused: 'Paused',
				msgCancelled: 'Отменен',
				msgPlaceholder: 'Select {files} ...',
				msgZoomModalHeading: 'Детайлен преглед',
				msgFileRequired: 'You must select a file to upload.',
				msgSizeTooSmall: 'File "{name}" (<b>{size} KB</b>) is too small and must be larger than <b>{minSize} KB</b>.',
				msgSizeTooLarge: 'Файла "{name}" (<b>{size} KB</b>) надвишава максималните разрешени <b>{maxSize} KB</b>.',
				msgFilesTooLess: 'Трябва да изберете поне <b>{n}</b> {files} файла.',
				msgFilesTooMany: 'Броя файлове избрани за качване <b>({n})</b> надвишава ограниченито от максимум <b>{m}</b>.',
				msgTotalFilesTooMany: 'You can upload a maximum of <b>{m}</b> files (<b>{n}</b> files detected).',
				msgFileNotFound: 'Файлът "{name}" не може да бъде намерен!',
				msgFileSecured: 'От съображения за сигурност не може да прочетем файла "{name}".',
				msgFileNotReadable: 'Файлът "{name}" не е четим.',
				msgFilePreviewAborted: 'Прегледа на файла е прекратен за "{name}".',
				msgFilePreviewError: 'Грешка при опит за четене на файла "{name}".',
				msgInvalidFileName: 'Invalid or unsupported characters in file name "{name}".',
				msgInvalidFileType: 'Невалиден тип на файла "{name}". Разрешени са само "{types}".',
				msgInvalidFileExtension: 'Невалидно разрешение на "{name}". Разрешени са само "{extensions}".',
				msgFileTypes: {
					'image': 'image',
					'html': 'HTML',
					'text': 'text',
					'video': 'video',
					'audio': 'audio',
					'flash': 'flash',
					'pdf': 'PDF',
					'object': 'object'
				},
				msgUploadAborted: 'Качите файла, бе прекратена',
				msgUploadThreshold: 'Processing &hellip;',
				msgUploadBegin: 'Initializing &hellip;',
				msgUploadEnd: 'Done',
				msgUploadResume: 'Resuming upload &hellip;',
				msgUploadEmpty: 'No valid data available for upload.',
				msgUploadError: 'Upload Error',
				msgDeleteError: 'Delete Error',
				msgProgressError: 'Error',
				msgValidationError: 'утвърждаване грешка',
				msgLoading: 'Зареждане на файл {index} от общо {files} &hellip;',
				msgProgress: 'Зареждане на файл {index} от общо {files} - {name} - {percent}% завършени.',
				msgSelected: '{n} {files} избрани',
				msgFoldersNotAllowed: 'Само пуснати файлове! Пропуснати {n} пуснати папки.',
				msgImageWidthSmall: 'Широчината на изображението "{name}" трябва да е поне {size} px.',
				msgImageHeightSmall: 'Височината на изображението "{name}" трябва да е поне {size} px.',
				msgImageWidthLarge: 'Широчината на изображението "{name}" не може да е по-голяма от {size} px.',
				msgImageHeightLarge: 'Височината на изображението "{name}" нее може да е по-голяма от {size} px.',
				msgImageResizeError: 'Не може да размерите на изображението, за да промените размера.',
				msgImageResizeException: 'Грешка при промяна на размера на изображението.<pre>{errors}</pre>',
				msgAjaxError: 'Something went wrong with the {operation} operation. Please try again later!',
				msgAjaxProgressError: '{operation} failed',
				msgDuplicateFile: 'File "{name}" of same size "{size} KB" has already been selected earlier. Skipping duplicate selection.',
				msgResumableUploadRetriesExceeded:  'Upload aborted beyond <b>{max}</b> retries for file <b>{file}</b>! Error Details: <pre>{error}</pre>',
				msgPendingTime: '{time} remaining',
				msgCalculatingTime: 'calculating time remaining',
				ajaxOperations: {
					deleteThumb: 'file delete',
					uploadThumb: 'file upload',
					uploadBatch: 'batch file upload',
					uploadExtra: 'form data upload'
				},
				dropZoneTitle: 'Пуснете файловете тук &hellip;',
				dropZoneClickTitle: '<br>(or click to select {files})',
				fileActionSettings: {
					removeTitle: 'Махни файл',
					uploadTitle: 'Качване на файл',
					uploadRetryTitle: 'Retry upload',
					downloadTitle: 'Download file',
					zoomTitle: 'Вижте детайли',
					dragTitle: 'Move / Rearrange',
					indicatorNewTitle: 'Все още не е качил',
					indicatorSuccessTitle: 'Качено',
					indicatorErrorTitle: 'Качи Error',
					indicatorPausedTitle: 'Upload Paused',
					indicatorLoadingTitle:  'Качва се &hellip;'
				},
				previewZoomButtonTitles: {
					prev: 'View previous file',
					next: 'View next file',
					toggleheader: 'Toggle header',
					fullscreen: 'Toggle full screen',
					borderless: 'Toggle borderless mode',
					close: 'Close detailed preview'
				}
			};
		}

		this._labels = {
			btnOK: 'OK',
			btnYes: 'Да',
			btnNo: 'Не',
			btnCancel: 'Отмени',
			btnConfirmRemove: 'Изтрий',
			optLanguage: 'bg',
			optLanguageLong: 'bg_BG',
			txtSuccessTitle: 'Успешно',
			txtErrorTitle: 'Грешка',
			txtInfoTitle: 'Информация',
			txtWarningTitle: 'Внимание',
			txtLoadingTitle: 'Зареждане...',
			txtLoadingMessage: 'Моля, изчакайте.',
			txtProcessingTitle: 'Обработка',
			txtProcessingMessage: 'Моля, изчакайте...',
			txtConfirmTitle: 'Потвърждение за изтриване',
			txtConfirmRemove: 'Сигурни ли сте, че желаете изтриването на запис #:id?',
			txtConfirmProceed: 'Сигурни ли сте, че желаете да продължите?',
			txtDatabaseErrorMessage: 'Грешка в базата данни: ',
			txtSynchronizationTitle: 'Синхронизация',
			txtSynchronizationMessage: 'Моля, изчакайте завършването на процеса.',
			txtNoSupportMessage: 'Офлайн заявките са ограничени поради липсата на поддръжка на IndexedDB.',
			txtValidationFailedMessage: 'Едно или повече от задължителните полета не са попълнени!',
			txtValidationInvalidPassword: 'Паролата трябва да бъде от минимум 8 символа и трябва да съдържа:<ul><li>Поне едно число от 0 до 9</li><li>Поне една главна буква (A-Z)</li><li>Поне една малка буква (a-z)</li><li>Поне един специален символ (!@#$% т.н.)</li></ul>',
			txtValidationInvalidCode: 'Невалиден код на интервенцията!',
			txtValidationInvalidConsistency: 'Невалидна последователност на дата и час!',
			txtExpireSoonMessage: 'Валидността на интервенцията скоро изтича.',
			txtMaxLengthPre: 'Въведени ',
			txtMaxLengthPost: ' позволени символа.',
			txtMaxLengthSeparator: ' от ',
			txtSwitchConfirm: 'Сигурни ли сте?',
			txtTableEmpty: 'Няма намерени записи',
			txtRequestFailedTitle: 'Грешка',
			txtRequestFailedMessage: 'Невъзможно извършването на текущата заявка!',
			txtOfflineRestrictionMessage: 'Действието не се поддържа в офлайн режим!',
			fiDropZoneClickTitle: '<br>(или щракнете за да изберете {files})',
			dtLanguage: {
				"decimal":        "",
				"emptyTable":     "Няма налична информация",
				"info":           "Показване на _START_ до _END_ от _TOTAL_ записа",
				"infoEmpty":      "Няма намерени записи",
				"infoFiltered":   "(филтрирани от _MAX_ записа общо)",
				"infoPostFix":    "",
				"thousands":      ",",
				"lengthMenu":     "Показани записи: _MENU_",
				"loadingRecords": "Зареждане...",
				"processing":     "Зареждане на данните...",
				"search":         "Търсене:",
				"zeroRecords":    "Няма открити съвпадения",
				"paginate": {
					"first":      '<i class="fal fa-angle-double-left"></i>',
					"last":       '<i class="fal fa-angle-double-right"></i>',
					"next":       '<i class="fal fa-angle-right"></i>',
					"previous":   '<i class="fal fa-angle-left"></i>'
				},
				"aria": {
					"sortAscending":  ": активирайте за сортиране във възходящ ред",
					"sortDescending": ": активирайте за сортиране във низходящ ред"
				}
			},
			dtAll: 'Всички',
			dtButtonExport: 'Експорт',
			dtButtonCopy: 'Копиране',
			dtButtonExcel: 'Excel',
			dtButtonPDF: 'PDF',
			dtButtonCSV: 'CSV',
			dtButtonPrint: 'Принтиране',
			dtButtonColumns: 'Видими колони',
			dtButtonColVis: '<i class="fal fa-columns"></i>',
			drpRanges: {
				'Днес': [moment(), moment()],
				'Вчера': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
				'Последните 48 часа': [moment().subtract(1, 'days'), moment()],
				'Последните 7 дни': [moment().subtract(6, 'days'), moment()],
				'Последните 30 дни': [moment().subtract(29, 'days'), moment()],
				'Този месец': [moment().startOf('month'), moment().endOf('month')],
				'Миналия месец': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
			},
			drpLocale: {
				"format": "DD.MM.YYYY",
				"separator": " - ",
				"applyLabel": "Приложи",
				"cancelLabel": "Отмени",
				"fromLabel": "От",
				"toLabel": "До",
				"customRangeLabel": "Персонализиран",
				"weekLabel": "W",
				"daysOfWeek": [
					"нд",
					"пн",
					"вт",
					"ср",
					"чт",
					"пт",
					"сб"
				],
				"monthNames": [
					"Януари",
					"Февруари",
					"Март",
					"Април",
					"Май",
					"Юни",
					"Юли",
					"Август",
					"Септември",
					"Октомври",
					"Ноември",
					"Декември"
				],
				"firstDay": 1
			},
			bmsNonSelected: 'Няма избрани',
			bmsManySelected: 'избрани',
			bmsAllSelected: 'Всички избрани',
		};
	}
}
