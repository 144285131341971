require('./bootstrap');
//require('pickadate');
require('./vendor/pickadate/picker');
require('./vendor/pickadate/picker.date');
require('./vendor/pickadate/picker.time');

//require('./vendor/tagging.min');
require('./vendor/tagging');

require('bootstrap-select');
require('./vendor/bootstrap-multiselect');

//require('./vendor/dropzone.min');

window.SignaturePad = require('signature_pad/dist/signature_pad').default;

require('bootstrap-fileinput/js/plugins/piexif');
require('bootstrap-fileinput/js/plugins/sortable');
require('bootstrap-fileinput/js/fileinput');
require('bootstrap-fileinput/themes/fa/theme');

require('bootstrap-input-spinner/src/bootstrap-input-spinner');

//require('eonasdan-bootstrap-datetimepicker/src/js/bootstrap-datetimepicker');
require('tempusdominus-bootstrap-4');
require('daterangepicker');

//require('./vendor/typeahead.bundle');
window.Bloodhound = require('./vendor/bloodhound');
require('./vendor/typeahead.jquery');

/*var $ = require('jquery');
require('datatables.net')(window, $);*/
require('datatables.net-bs4');
require('datatables.net-autofill-bs4');
require('datatables.net-buttons-bs4');
require('datatables.net-buttons/js/buttons.colVis');
require('datatables.net-buttons/js/buttons.html5');
require('datatables.net-buttons/js/buttons.print');
require('datatables.net-colreorder-bs4');
require('datatables.net-fixedcolumns-bs4');
require('datatables.net-fixedheader-bs4');
require('datatables.net-keytable-bs4');
require('datatables.net-responsive-bs4');
require('datatables.net-rowgroup-bs4');
require('datatables.net-rowreorder-bs4');
require('datatables.net-scroller-bs4');
require('datatables.net-select-bs4');

require('jquery.countdown');

window.inputmask = require('inputmask/dist/jquery.inputmask');

window.toastr = require('toastr/toastr');
window.Swal = require('sweetalert2');
//window.Swal = require('./vendor/sweetalert2.all');

require('./script');
