import { Translation } from './translation';

const { isEmptyObject } = require("jquery");
const { clone } = require("lodash");
const moment = require("moment");

var App = (function($)
{
	const DB_NAME = 'securitas';
	const DB_VERSION = 1;
	const DB_STORE = 'requests';
	const DB_KEY = 'field';

	var _translation;
	var _is_offline = false;
	var _db,
		_store,
		_data;
	var _password_strength = 0;

	var _ajaxSetup = (function()
	{
		$.ajaxSetup(
		{
			headers: {
				'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
			}
		});
	});

	var _initializeTranslation = (function()
	{
		if (typeof(_translation) !== 'undefined')
		{
			return;
		}

		_translation = new Translation();
	});

	var _getLabel = (function(code, placeholder, value)
	{
		_initializeTranslation();

		return _translation.getLabel(code, placeholder, value);
	});

	var _handleColumns = (function(table)
	{
		var columnDefs = [];
		var columns = [];
		var order = [];
		var buttons = [];

		if (typeof(table.data('init-defaults')) !== 'undefined')
		{
			columnDefs = table.data('init-defaults');
		}

		if (typeof(table.data('init-columns')) !== 'undefined')
		{
			columns = table.data('init-columns');
		}

		if (typeof(table.data('init-order')) !== 'undefined')
		{
			order = table.data('init-order');
		}

		if (typeof(table.data('init-export')) !== 'undefined')
		{
			buttons = [
			{
				extend: 'collection',
				text: _getLabel('dtButtonExport'),
				className: 'btn btn-lilac',
				buttons: [
				{
					extend: 'copyHtml5',
					text: _getLabel('dtButtonCopy'),
					className: 'btn btn-lilac',
					exportOptions:
					{
						columns: table.data('init-export')
					}
				},
				{
					extend: 'excelHtml5',
					text: _getLabel('dtButtonExcel'),
					className: 'btn btn-lilac',
					exportOptions:
					{
						columns: table.data('init-export')
					}
				},
				{
					extend: 'csvHtml5',
					text: _getLabel('dtButtonCSV'),
					className: 'btn btn-lilac',
					exportOptions:
					{
						columns: table.data('init-export')
					}
				},
				{
					extend: 'print',
					text: _getLabel('dtButtonPrint'),
					className: 'btn btn-lilac',
					exportOptions:
					{
						columns: table.data('init-export')
					}
				}
				]
			},
			{
				extend: 'colvis',
				text: _getLabel('dtButtonColVis'),
				className: 'btn btn-lilac',
				columns: ((typeof(table.data('init-visible')) !== 'undefined') ? table.data('init-visible') : table.data('init-export'))
			}
			];
		}

		return {
			columnDefs: columnDefs,
			columns: columns,
			order: order,
			buttons: buttons
		};
	});

	var _getNumberOfRecords = (function(type, element, offset)
	{
		var result = 0;

		if (typeof(type) === 'undefined' || typeof(element) === 'undefined' || !element.length)
		{
			return result;
		}

		switch (type)
		{
			case 'max': // Get the maximum number of records allowed
				if (typeof(element.data('max-records')) !== 'undefined' && parseInt(element.data('max-records')) > 0)
				{
					result = parseInt(element.data('max-records'));
				}

				break;
			case 'total': // Get the total number of records present
				result = element.children('.row').length;

				if (typeof(offset) !== 'undefined' && parseInt(offset) > 0)
				{
					result -= offset;
				}

				break;
		}

		return result;
	});

	var _toggleAddDataButton = (function(selector)
	{
		if (!$(selector).length)
		{
			return;
		}

		$(selector).each(function()
		{
			var $this = $(this);

			var $section = $this.parents('[id]');

			if ($section.length && $section.children('.row').length)
			{
				var max_records = _getNumberOfRecords('max', $section);

				var total_records = _getNumberOfRecords('total', $section, 2);

				if (max_records > 0)
				{
					$this.toggleClass('d-none', (total_records >= max_records));
				}
				else
				{
					$this.toggleClass('d-none', !$this.hasClass('d-none'));
				}
			}
			else
			{
				$this.toggleClass('d-none', false);
			}
		});
	});

	var _toggleRemoveDataButton = (function(selector)
	{
		if (!$(selector).length)
		{
			return;
		}

		$(selector).each(function()
		{
			var $this = $(this);

			var $section = $this.parents('[id]');

			if ($section.length && $section.children('.row').length)
			{

				$this.toggleClass('d-none', (_getNumberOfRecords('total', $section, 2) < 2));
			}
			else
			{
				$this.toggleClass('d-none', false);
			}
		});
	});

	var _toggleRemoveAdditionalButton = (function(selector)
	{
		if (!$(selector).length)
		{
			return;
		}

		$(selector).each(function()
		{
			var $this = $(this);

			var $section = $this.parents('[id]');

			if ($section.length && $section.children('.row').length)
			{

				$this.toggleClass('d-none', ($section.children('.row').length < 3));
			}
			else
			{
				$this.toggleClass('d-none', true);
			}
		});
	});

	var _triggerControl = (function(selector)
	{
		if (!$(selector).length)
		{
			return;
		}

		$(selector).each(function()
		{
			var $this = $(this);

			if ($this.attr('type') === 'radio' || $this.attr('type') === 'checkbox')
			{
				var $parent = $this.parent('label');

				$parent.on('click', function()
				{
					var control = $this.data('trigger-control');

					if (control.length && $(control).length)
					{
						if ($(control).attr('type') === 'radio' || $(control).attr('type') === 'checkbox')
						{
							$(control).parent('label').trigger('click');
						}
					}
				});
			}
		});
	});

	var _toggleControls = (function($control, params)
	{
		if (!$control || !$control.length)
		{
			return;
		}

		//console.log('Function _toggleControls');
		//console.log($control);

		var controls = '';

		if (typeof($control.data('toggle-controls')) !== 'undefined' && $control.data('toggle-controls').length)
		{
			controls = $control.data('toggle-controls').split(',');
		}

		//console.log('Controls to toggle:');
		//console.log(controls);

		if (!controls.length)
		{
			return;
		}

		var clear = false;
		var focus = false;
		var required = false;

		if (typeof(params) !== 'undefined' && !$.isEmptyObject(params))
		{
			if (typeof(params.clear) !== 'undefined' && params.clear === true)
			{
				clear = true;
			}

			if (typeof(params.focus) !== 'undefined' && params.focus === true)
			{
				focus = true;
			}

			if (typeof(params.required) !== 'undefined' && params.required === true)
			{
				required = true;
			}
		}

		$.each(controls, function(index, control)
		{
			//console.log('Current control:');
			//console.log(control);

			if (!control.length || !$(control).length)
			{
				return;
			}

			//console.log($(control));

			var id = $control.attr('id');
			var name = $control.attr('name');
			var value = parseInt($control.val());
			var attribute = 'readonly';
			var is_typeahead = ($(control).hasClass('typeahead'));

			var current = (index + 1);

			//console.log('ID: ' + id);
			//console.log('Name: ' + name);
			//console.log('Value: ' + value);
			//console.log('Control: ' + control);

			if ($(control).parent('.datetime-picker').length)
			{
				attribute = 'disabled';
			}

			if (name === 'patrol_type')
			{
				if ($control.is(':checked') && value > 0)
				{
					//console.log('Checked and value of 1!');
					if (current === 1)
					{
						//focus = false;
						clear = false;
					}

					if (value === 2 && current === 2)
					{
						$(control).attr(attribute, false);
					}

					if (required === true)
					{
						if (current <= value && !$(control).val().length)
						{
							$(control).addClass('mandatory');
							$(control).attr('data-required', true);
						}
						else
						{
							$(control).removeClass('mandatory');
							$(control).removeAttr('data-required');
						}

						if (current === 2)
						{
							if (value === 2 && !$(control).parents('div.form-group').find('label').hasClass('required'))
							{
								$(control).parents('div.form-group').find('label').addClass('required');
							}
							else if (value === 1 && $(control).parents('div.form-group').find('label').hasClass('required'))
							{
								$(control).parents('div.form-group').find('label').removeClass('required');
							}
						}
					}

					if (focus === true)
					{
						$(control).trigger('focus');

						focus = false;
					}
				}
				else
				{
					clear = false;

					if (current === 2)
					{
						$(control).attr(attribute, true);

						clear = true;
					}

					if (required === true/* && current <= value*/)
					{
						$(control).removeClass('mandatory');
						$(control).removeAttr('data-required');

						if (current === 2 && $(control).parents('div.form-group').find('label').hasClass('required'))
						{
							$(control).parents('div.form-group').find('label').removeClass('required');
						}
					}
				}
			}
			else if (name === 'armed_status')
			{
				if ($control.is(':checked') && value > 0)
				{
					//console.log('Checked and value of 1!');
					if (current === value)
					{
						$(control).attr(attribute, false);
					}
					else
					{
						$(control).attr(attribute, true);
					}

					if (required === true)
					{
						if ((index + 1) === value)
						{
							$(control).addClass('mandatory');
							$(control).attr('data-required', true);
						}
						else
						{
							$(control).removeClass('mandatory');
							$(control).removeAttr('data-required');
						}
					}

					if (focus === true)
					{
						$(control).trigger('focus');

						focus = false;
					}
				}
				else
				{
					//console.log('Unchecked and/or value different than 1!');
					$(control).attr(attribute, true);

					if (required === true)
					{
						$(control).removeClass('mandatory');
						$(control).removeAttr('data-required');
					}
				}
			}
			else if (name === 'is_billable')
			{
				if (id.indexOf('billing') === -1)
				{
					if ($control.is(':checked') && value === 2)
					{
						//console.log('Checked and value of 2!');
						$(control).attr(attribute, false);

						if (required === true)
						{
							$(control).addClass('mandatory');
							$(control).attr('data-required', true);

							if (!$(control).prev('label').hasClass('required'))
							{
								$(control).prev('label').addClass('required');
							}
						}

						if (focus === true)
						{
							$(control).trigger('focus');

							focus = false;
						}
					}
					else
					{
						//console.log('Unchecked and/or value different than 2!');
						$(control).attr(attribute, true);

						if (required === true)
						{
							$(control).removeClass('mandatory');
							$(control).removeAttr('data-required');

							if ($(control).prev('label').hasClass('required'))
							{
								$(control).prev('label').removeClass('required');
							}
						}
					}
				}
				else
				{
					if ($control.is(':checked') && value === 1)
					{
						//console.log('Checked and value of 1!');
						$(control).attr(attribute, false);

						if (required === true)
						{
							$(control).addClass('mandatory');
							$(control).attr('data-required', true);
						}

						if (focus === true)
						{
							$(control).trigger('focus');

							focus = false;
						}
					}
					else
					{
						//console.log('Unchecked and/or value different than 1!');
						$(control).attr(attribute, true);

						if (required === true)
						{
							$(control).removeClass('mandatory');
							$(control).removeAttr('data-required');
						}
					}
				}
			}
			else if (id === 'mia_1_id' || id === 'mia_2_id')
			{
				var is_checked = $(control).is(':checked');

				value = $('#' + id + '_selected').val();

				if (id === 'mia_1_id')
				{
					if (value > 0)
					{
						if (control === '#single_patrol' && !is_checked)
						{
							$(control).parent('label').trigger('click');
						}
					}
					else if (is_checked)
					{
						$(control).parent('label').trigger('click');
					}
				}
				else if (control === '#double_patrol')
				{
					if (value > 0)
					{
						if (!is_checked)
						{
							$(control).parent('label').trigger('click');
						}
					}
					else if (is_checked)
					{
						$(control).parent('label').trigger('click');
					}
				}
			}
			else
			{
				if ($control.is(':checked') && value === 1)
				{
					//console.log('Checked and value of 1!');
					$(control).attr(attribute, false);

					if (required === true)
					{
						$(control).addClass('mandatory');
						$(control).attr('data-required', true);

						if (!$(control).prev('label').hasClass('required'))
						{
							$(control).prev('label').addClass('required');
						}
					}

					if (focus === true)
					{
						$(control).trigger('focus');

						focus = false;
					}
				}
				else
				{
					//console.log('Unchecked and/or value different than 1!');
					$(control).attr(attribute, true);

					if (required === true)
					{
						$(control).removeClass('mandatory');
						$(control).removeAttr('data-required');

						if ($(control).prev('label').hasClass('required'))
						{
							$(control).prev('label').removeClass('required');
						}
					}
				}
			}

			if (clear === true)
			{
				if (is_typeahead)
				{
					$(control).typeahead('val', '');
				}
				else
				{
					$(control).val('');
				}

				$(control).trigger('change');
			}
		});
	});

	var _showControl = (function(selector)
	{
		if (!$(selector).length)
		{
			return;
		}

		$(selector).each(function()
		{
			var $this = $(this);

			if ($this.attr('type') === 'radio' || $this.attr('type') === 'checkbox')
			{
				var $parent = $this.parent('label');

				$parent.on('click', function()
				{
					var control = $this.data('show-control');

					if (control.length && $(control).length)
					{
						if ($this.is(':checked'))
						{
							$(control).removeClass('d-none');
						}
						else
						{
							$(control).addClass('d-none');
						}
					}
				});
			}
		});
	});

	var _compareValues = (function(source, destination, special)
	{
		var result = true;

		if (typeof(special) !== 'undefined' && special === true)
		{
			var current_values = [];

			if (destination)
			{
				if (destination.indexOf('{') !== -1)
				{
					current_values = JSON.parse(destination);
				}
				else
				{
					current_values = destination.split(',');
				}
			}

			result = false;

			for (var i = 0; i < current_values.length; i++)
			{
				if (source === current_values[i])
				{
					result = true;
					break;
				}
			}
		}
		else
		{
			if (source !== destination)
			{
				result = false;
			}
		}

		return !result;
	});

	var _initializeOnlineStatus = (function()
	{
		_is_offline = (!navigator.onLine);

		if (_is_offline)
		{
			_toggleOnlineStatusNotification('#notification');
		}
	});

	var _checkOnlineStatus = (function()
	{
		$(window).on('online offline', function()
		{
			_is_offline = (!window.navigator.onLine);

			_toggleOnlineStatusNotification('#notification');

			_runSync();
		});
	});

	var _toggleOnlineStatusNotification = (function(selector)
	{
		if (!$(selector).length)
		{
			return;
		}

		var $this = $(selector);
		var status = 'online';

		if (_is_offline)
		{
			status = 'offline';
		}

		var title = ((typeof($this.data(status + '-title')) !== 'undefined' && $this.data(status + '-title').length) ? $this.data(status + '-title') : '');
		var content = ((typeof($this.data(status + '-content')) !== 'undefined' && $this.data(status + '-content').length) ? $this.data(status + '-content') : '');

		if (title.length && content.length)
		{
			$this.html('<strong>' + title + '</strong>' + content).removeClass().addClass(status);

			if (status === 'online')
			{
				setInterval(function()
				{
					$this.html('').removeClass().addClass('d-none');
				}, 5000);
			}
		}
	});

	var _validateSection = (function($control)
	{
		var result = {
			valid: true,
			element: '',
		}

		var $section = $control.parents('section');

		if (!$section.length)
		{
			return result;
		}

		var $controls = $section.find('[id][data-required="true"]');
		var total_controls = $controls.length;
		var valid_controls = 0;

		if (!total_controls)
		{
			return result;
		}

		//console.log($controls);

		$controls.each(function()
		{
			var $this = $(this);

			var tag = $this.prop('tagName').toLowerCase();
			var type = ((tag === 'input' && typeof($this.attr('type')) !== 'undefined') ? $this.attr('type') : '');
			var control_name = (typeof($this.prop('name')) !== 'undefined' ? $this.prop('name') : '');
			var name = (control_name ? control_name.replace(/\[\]$/i, '').replace(/\[\w+\]/i, '').replace(/\[\d\]$/i, '') : $this.prop('id'));
			var value = $this.val();

			if ($control.attr('name') === name || $this.prop('readonly') || $this.prop('disabled'))
			{
				total_controls--;

				return;
			}

			//console.log('Tag: ' + tag);
			//console.log('Type: ' + type);
			//console.log('Name: ' + name);
			//console.log('Value: ' + value);

			var options =
			{
				tag: tag,
				type: type,
				name: name,
				value: value,
				source: $this,
			};

			//console.log(options);

			var is_valid = _validateControl(options);

			//console.log('Is valid: ' + is_valid);

			if (is_valid)
			{
				valid_controls++;
			}
			else if (!result.element.length)
			{
				result.element = '#' + $this.attr('id');
			}
		});

		//console.log('Total controls: ' + total_controls + ' / Valid controls: ' + valid_controls);

		if (total_controls !== valid_controls)
		{
			result.valid = false;
		}

		//console.log(result);

		return result;
	});

	var _validateControl = (function(params)
	{
		var result = false;

		if (typeof(params) === 'undefined' || $.isEmptyObject(params))
		{
			return false;
		}

		//console.log(params);

		if (!params.type && params.tag === 'select')
		{
			params.type = 'select';
		}

		switch (params.type)
		{
			case 'text':
			case 'number':
				if (!params.source || typeof(params.source.data('required')) === 'undefined' || params.source.data('required') !== true)
				{
					return true;
				}

				if (params.value && params.value.length)
				{
					result = true;

					params.source.removeClass('mandatory');
				}
				else
				{
					result = false;

					params.source.addClass('mandatory');
				}

				break;
			case 'radio':
				if (!params.source || !params.name)
				{
					return true;
				}

				var $controls = $('input[type="' + params.type + '"][name="' + params.name + '"][data-required="true"]');

				if (!$controls || !$controls.length)
				{
					return false;
				}

				if (!$controls.is(':checked'))
				{
					result = false;

					$controls.parent('label').addClass('mandatory');
				}
				else
				{
					result = true;

					$controls.parent('label').removeClass('mandatory');
				}

				break;
			case 'checkbox':
				break;
			case 'select':
				if (!params.source || typeof(params.source.data('required')) === 'undefined' || params.source.data('required') !== true)
				{
					return true;
				}

				if (params.value && parseInt(params.value) > 0)
				{
					result = true;

					params.source.removeClass('mandatory');
				}
				else
				{
					result = false;

					params.source.addClass('mandatory');
				}

				break;
			}

		return result;
	});

	var _ajaxSync = (function(params)
	{
		var tag,
			type,
			path,
			name,
			value;
		var index = '';
		var source = null;
		var synced = null;

		if (typeof(params) === 'undefined' || $.isEmptyObject(params))
		{
			return false;
		}

		if (typeof(params.tag) !== 'undefined' && params.tag.length)
		{
			tag = params.tag;
		}

		if (typeof(params.type) !== 'undefined' && params.type.length)
		{
			type = params.type;
		}

		if (typeof(params.path) !== 'undefined' && params.path.length)
		{
			path = params.path;
		}

		if (typeof(params.name) !== 'undefined' && params.name.length)
		{
			name = params.name;
		}

		if (typeof(params.value) !== 'undefined' && params.value.length)
		{
			value = params.value;
		}

		if (typeof(params.index) !== 'undefined' && params.index.length)
		{
			index = params.index;
		}

		if (typeof(params.source) !== 'undefined' && params.source.length)
		{
			source = params.source;
		}

		if (typeof(params.synced) !== 'undefined' && params.synced.length)
		{
			synced = params.synced;
		}

		if (typeof(value) === 'undefined')
		{
			value = (type === 'radio' ? 0 : '');
		}

		if (!path.length || !name.length)
		{
			return false;
		}

		$.ajax(
		{
			type: 'POST',
			url: path,
			data: 'field=' + name + '&value=' + encodeURIComponent(value) + '&index=' + index,
			dataType: 'json',
			async: false,
			beforeSend: function()
			{
				if (source && source.length)
				{
					source.addClass('sync_processing').removeClass('sync_success sync_awaiting sync_error');
				}
			},
			success: function(data)
			{
				if (!$.isEmptyObject(data))
				{
					if (data.value && data.value.length && synced && synced.length)
					{
						if (type === 'checkbox')
						{
							//synced.val(JSON.stringify(data.value));
							synced.val(data.value);
						}
						else
						{
							synced.val(data.value);
						}
					}

					if (data.record && $('#record_id').length)
					{
						$('#record_id').val(data.record);
					}

					_handleShowAction(data.additional.show);

					_handleHideAction(data.additional.hide);

					if (source && source.length)
					{
						source.removeClass('sync_processing sync_success sync_awaiting sync_error mandatory').addClass('sync_' + data.status);

						if (source.hasClass('sync_success') || source.hasClass('sync_error'))
						{
							setTimeout(function()
							{
								source.removeClass('sync_success sync_error');

								_validateControl({
									tag: tag,
									type: type,
									name: name,
									value: data.value,
									source: source,
									synced: synced,
								});
							}, 5000);
						}
					}
				}
			},
			complete: function(jqXHR, textStatus)
			{
				if (source && source.length)
				{
					if (textStatus === 'timeout')
					{
						source.addClass('sync_awaiting').removeClass('sync_processing sync_success sync_error');
					}
				}
			}
		});

		return true;
	});

	var _triggerSync = (function(selector, value)
	{
		if (typeof(selector) === 'undefined' || !$(selector).length)
		{
			return false;
		}

		var $control = $(selector);
		var tag = $control.prop('tagName').toLowerCase();
		var type = ((tag === 'input' && typeof($control.attr('type')) !== 'undefined') ? $control.attr('type') : '');
		var control_name = (typeof($control.prop('name')) !== 'undefined' ? $control.prop('name') : '');
		var name = (control_name ? control_name.replace(/\[\]$/i, '').replace(/\[\w+\]/i, '').replace(/\[\d\]$/i, '') : $control.prop('id'));
		var key = ((control_name && control_name.match(/\[\w+\]\[\d\]$/i)) ? control_name.replace(/^\w+\[(\w+)\]\[\d\]$/i, '$1') : '');
		var index = ((control_name && control_name.match(/^\w+\[\w+\]\[\d\]$/i)) ? control_name.replace(/^\w+\[\w+\]\[(\d)\]$/i, '$1') : ((control_name && control_name.match(/^\w+\[\d\]$/i)) ? control_name.replace(/^\w+\[(\d)\]$/i, '$1') :''));

		if (tag === 'div' && $control.hasClass('tagging'))
		{
			name = name.replace('Tags', '');
		}

		//console.log('Tag: ' + tag);
		//console.log('Type: ' + type);
		//console.log('Name: ' + name);
		//console.log('Key: ' + key);
		//console.log('Index: ' + index);

		var source_value = (typeof(value) !== 'undefined' ? value : $control.val());
		var initial_value = source_value;
		var synced_value = '';

		var $source_control = $control;
		var $synced_control;

		if ($('#' + name + '_synced').length)
		{
			$synced_control = $('#' + name + '_synced');

			if (typeof($synced_control) !== 'undefined' && $synced_control.length)
			{
				synced_value = $synced_control.val();
			}
		}

		if ((type === 'radio' || type === 'checkbox') && $('#' + $control.attr('id')).parent('label'))
		{
			$source_control = $('#' + $control.attr('id')).parent('label');

			if (!$('#' + $control.attr('id')).is(':checked'))
			{
				source_value = 0;
			}
		}

		if (type === 'text' && key.length)
		{
			var $parent = $source_control.parents('div.sync');

			var structure = {};

			if (typeof($parent.data('columns')) !== 'undefined')
			{
				structure = Object.assign({}, $parent.data('columns'));
			}

			if ($parent.find('input[type="text"]').length)
			{
				$parent.find('input[type="text"]').each(function()
				{
					var data = $(this).attr('id').split('_');

					if (typeof(structure[data[1]]) === 'string')
					{
						structure[data[1]] = {};
					}

					structure[data[1]][data[2]] = $(this).val();
				});
			}

			if (!$.isEmptyObject(structure))
			{
				source_value = JSON.stringify(structure);
			}
		}

		//console.log(source_value);
		//console.log(synced_value);

		if (typeof($synced_control) !== 'undefined' && $synced_control.length && _compareValues(source_value, synced_value, ((type === 'checkbox' || (type === 'text' && index > 0)) ? true : false)))
		{
			var id = 0;
			var route = '';
			var value = (type === 'checkbox' ? initial_value : source_value);
			var path;

			if ($('#record_id').length)
			{
				id = parseInt($('#record_id').val());
			}

			if (!$source_control.parents('form').length || typeof($source_control.parents('form').data('route')) === 'undefined' || !$source_control.parents('form').data('route').length)
			{
				return false;
			}

			route = $source_control.parents('form').data('route');

			path = '/' + route + '/sync' + (id > 0 ? '/' + id : '');

			_ajaxSetup();

			if (!_is_offline) // Normal flow (online)
			{
				//console.log('Normal flow');
				//console.log('Type: ', type);
				//console.log('Path: ', path);
				//console.log('Name: ', name);
				//console.log('Value: ', value);
				//console.log('Index: ', index);
				//console.log('Source: ', $source_control);
				//console.log('Synced: ', $synced_control);
				_ajaxSync({
					'tag': tag,
					'type': type,
					'path': path,
					'name': name,
					'value': value,
					'index': index,
					'source': $source_control,
					'synced': $synced_control,
				});
			}
			else // Preserve the request flow (offline)
			{
				$source_control.addClass('sync_awaiting').removeClass('sync_success sync_processing sync_error');

				var data = {
					'field': name,
					'value': value,
					'index': index,
					'path': path,
					'tag': tag,
					'type': type,
					'source': ($source_control.attr('id') ? $source_control.attr('id') : $source_control.attr('for')),
					'synced': $synced_control.attr('id'),
				};

				_addStoreData(data);
			}
		}
	});

	var _handleShowAction = (function(data)
	{
		if (typeof(data) !== 'undefined' && !$.isEmptyObject(data))
		{
			if ($('#' + data.control).length)
			{
				var $element = $('#' + data.control);

				if ($element.hasClass('d-none'))
				{
					$element.removeClass('d-none');
				}

				if (data.control === 'timer')
				{
					$element.html('<h1 data-elapsed="true" data-tick="true" class="timer timer-lg' + ((data.data.class.length && !$element.hasClass(data.data.class)) ? ' ' + data.data.class : '') + '">' + data.data.value + '</h1>');

					App.handleCountdown('.timer', true);
				}
			}
		}
	});

	var _handleHideAction = (function(data)
	{
		if (typeof(data) !== 'undefined' && !$.isEmptyObject(data))
		{
			if ($('#' + data.control).length)
			{
				var $element = $('#' + data.control);

				if (!$element.hasClass('d-none'))
				{
					$element.addClass('d-none');
				}

				if (data.control === 'timer')
				{
					$element.html('');
				}
			}
		}
	});

	var _handleEnableAction = (function(data)
	{
		if (typeof(data) !== 'undefined' && !$.isEmptyObject(data))
		{
			$.each(data, function(index, value)
			{
				var selector = '[name="' + value + '"]';

				if (value.indexOf('#') === 0)
				{
					selector = value;
				}

				if (value.indexOf('.') === 0)
				{
					selector = value;
				}

				if (!$(selector).length)
				{
					selector = '[data-action="' + value + '"]';
				}

				if ($(selector).length)
				{
					var tag = $(selector).prop('tagName').toLowerCase();

					if (tag === 'button' && $(selector).prop('disabled'))
					{
						$(selector).attr('disabled', false);
					}
					else if (tag === 'div')
					{
						if ($(selector).find('input[type="text"]').length)
						{
							$(selector).find('input[type="text"]').each(function()
							{
								if ($(this).prop('readonly'))
								{
									$(this).attr('readonly', false);
								}
							});
						}
					}
					else if ($(selector).prop('readonly'))
					{
						$(selector).attr('readonly', false);
					}
				}
			});
		}
	});

	var _handleDisableAction = (function(data)
	{
		if (typeof(data) !== 'undefined' && !$.isEmptyObject(data))
		{
			$.each(data, function(index, value)
			{
				var selector = '[name="' + value + '"]';

				if (value.indexOf('#') === 0)
				{
					selector = value;
				}

				if (value.indexOf('.') === 0)
				{
					selector = value;
				}

				if (!$(selector).length)
				{
					selector = '[data-action="' + value + '"]';
				}

				if ($(selector).length)
				{
					var tag = $(selector).prop('tagName').toLowerCase();

					if (tag === 'button' && !$(selector).prop('disabled'))
					{
						$(selector).attr('disabled', true);
					}
					else if (tag === 'div')
					{
						if ($(selector).find('input[type="text"]').length)
						{
							$(selector).find('input[type="text"]').each(function()
							{
								if (!$(this).prop('readonly'))
								{
									$(this).attr('readonly', true);
								}
							});
						}
					}
					else if (!$(selector).prop('readonly'))
					{
						$(selector).attr('readonly', true);
					}
				}
			});
		}
	});

	var _handleAddToHome = (function(selector)
	{
		if (!$(selector).length)
		{
			return;
		}

		var deferredPrompt;

		var $button = $(selector);

		$button.css('display', 'none');

		$(window).on('beforeinstallprompt', function(ev)
		{
			// Prevent Chrome 67 and earlier from automatically showing the prompt
			ev.preventDefault();

			// Stash the event so it can be triggered later.
			deferredPrompt = ev;

			// Update UI to notify the user they can add to home screen
			$button.css('display', 'block');

			$button.on('click', function(ev)
			{
				// hide our user interface that shows our A2HS button
				$(this).css('display', 'none');

				// Show the prompt
				deferredPrompt.prompt();

				// Wait for the user to respond to the prompt
				deferredPrompt.userChoice.then((choiceResult) => {
					if (choiceResult.outcome === 'accepted')
					{
						//console.log('User accepted the A2HS prompt');
					}
					else
					{
						//console.log('User dismissed the A2HS prompt');
					}
					deferredPrompt = null;
				});
			});
		});
	});

	var _handleRemoveConfirmation = (function(selector)
	{
		if (!$(selector).length)
		{
			return;
		}

		$(selector).each(function()
		{
			$(this).on('click', function(ev)
			{
				ev.preventDefault();

				var $form = $(this).parent('form');
				var id = $form.attr('action').replace(/^[a-z0-9\:\/\.\-]+\/(\d+)$/ig, '$1');

				Swal.fire(
				{
					title: _getLabel('txtConfirmRemove', ':id', id),
					showCancelButton: true,
					confirmButtonText: _getLabel('btnYes'),
					cancelButtonText: _getLabel('btnNo'),
					buttonsStyling: false,
					customClass: {
						confirmButton: 'btn btn-success btn-lg',
						cancelButton: 'btn btn-danger btn-lg ml-3',
					},
					onBeforeOpen: _attachCSPCode,
				}).then((result) => {
					if (result.isConfirmed)
					{
						$form.trigger('submit');
					}
				});
			});
		});
	});

	var _triggerWindowOpen = (function(url)
	{
		if (!url.length)
		{
			return;
		}

		var tab = window.open(url, _hashString(url, 'sha1'));

		//console.log(tab);
	});

	var _hashString = (function(string, algorithm)
	{
		if (!string.length)
		{
			return '';
		}

		if (typeof(algorithm) === 'undefined' || !(['sha1', 'md5'].includes(algorithm)))
		{
			algorithm = 'md5';
		}

		const crypto = require('crypto');

		return crypto.createHash(algorithm).update(string).digest('hex');
	});

	var _initializeDatabase = (function()
	{
		//window.indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;

		if (!indexedDB)
		{
			App.showToastr('error', _getLabel('txtNoSupportMessage'), _getLabel('txtErrorTitle'), { "timeOut": 2000, "progressBar": false });

			return;
		}

		var request = indexedDB.open(DB_NAME, DB_VERSION);

		request.onerror = function(event)
		{
			App.showToastr('error', _getLabel('txtDatabaseErrorMessage') + event.target.errorCode, _getLabel('txtErrorTitle'), { "timeOut": 2000, "progressBar": true });
		};

		request.onsuccess = function(event)
		{
			_db = event.target.result;
		};

		request.onupgradeneeded = function(event)
		{
			_db = event.target.result;

			_store = _db.createObjectStore(DB_STORE, { keyPath: DB_KEY });

			_store.createIndex(DB_KEY, DB_KEY, { unique: true });
		};
	});

	var _addStoreData = (function(data)
	{
		if (!_db)
		{
			App.showToastr('error', _getLabel('txtNoSupportMessage'), _getLabel('txtErrorTitle'), { "timeOut": 2000, "progressBar": false });

			return;
		}

		var transaction = _db.transaction([DB_STORE], 'readwrite');

		transaction.oncomplete = function()
		{
			//console.log('Success transaction');
		};

		var request = transaction.objectStore(DB_STORE).put(data);

		request.onsuccess = function(event)
		{
			//console.log(event.target.result);
		};
	});

	var _removeStoreData = (function(field)
	{
		if (!_db)
		{
			App.showToastr('error', _getLabel('txtNoSupportMessage'), _getLabel('txtErrorTitle'), { "timeOut": 2000, "progressBar": false });

			return;
		}

		var transaction = _db.transaction([DB_STORE], 'readwrite');

		transaction.oncomplete = function()
		{
			//console.log('Success transaction');
		};

		var request = transaction.objectStore(DB_STORE).delete(field);

		request.onsuccess = function(event)
		{
			//console.log('Success remove');
		};
	});

	var _clearStoreData = (function()
	{
		if (!_db)
		{
			return;
		}

		var transaction = _db.transaction([DB_STORE], 'readwrite');

		transaction.oncomplete = function()
		{
			//console.log('Success transaction');
		};

		var request = transaction.objectStore(DB_STORE).clear();

		request.onsuccess = function(event)
		{
			//console.log('Success clear');
		};
	});

	var _runSync = (function()
	{
		if (_is_offline)
		{
			return;
		}

		// Set a second delay before start the sync process
		setTimeout(function()
		{
			_batchSync();
		}, 1000);
	});

	var _batchSync = (function()
	{
		if (!_db)
		{
			App.showToastr('error', _getLabel('txtNoSupportMessage'), _getLabel('txtErrorTitle'), { "timeOut": 2000, "progressBar": false });

			return;
		}

		var transaction = _db.transaction([DB_STORE], 'readwrite');

		var request = transaction.objectStore(DB_STORE).getAll();

		request.onsuccess = function(event)
		{
			if (!$.isEmptyObject(event.target.result))
			{
				App.showToastr('info', _getLabel('txtSynchronizationMessage'), _getLabel('txtSynchronizationTitle'), { "timeOut": 0, "progressBar": false });

				$.each(event.target.result, function(index, data)
				{
					var $source,
						$synced;

					if ($('#' + data.source).length)
					{
						if (data.type === 'radio' || data.type === 'checkbox')
						{
							$source = $('#' + data.source).parent('label');
						}
						else
						{
							$source = $('#' + data.source);
						}
					}

					if ($('#' + data.synced).length)
					{
						$synced = $('#' + data.synced);
					}

					// Set a second delay before running every single sync request
					setTimeout(function()
					{
						var result = _ajaxSync({
							'tag': data.tag,
							'type': data.type,
							'path': data.path,
							'name': data.field,
							'value': data.value,
							'index': data.index,
							'source': $source,
							'synced': $synced,
						});

						if (result)
						{
							_removeStoreData(data.field);
						}
					}, 1000);
				});

				toastr.clear();
			}
		};
	});

	var _clearWorkerCache = (function()
	{
		caches.keys().then(cacheNames => {
			cacheNames.forEach(cacheName => {
				if (cacheName === 'document-cache' || cacheName === 'page-cache')
				{
					caches.delete(cacheName);
				}
			});
		});
	});

	var _changeFileInputIconLink = (function(selector)
	{
		if (!$(selector).length)
		{
			return;
		}

		$(selector).each(function()
		{
			var $this = $(this);
			var $parent = $this.parents('.kv-file-content');
			var link = '';

			if ($parent.length && $parent.next('.file-thumbnail-footer').length && $parent.next('.file-thumbnail-footer').find('a.kv-file-download').length)
			{
				link = $parent.next('.file-thumbnail-footer').find('a.kv-file-download').attr('href');
			}

			if (link.length)
			{
				if ((typeof($this.attr('data')) !== 'undefined' && $this.attr('data').length) || (typeof($this.attr('src')) !== 'undefined' && $this.attr('src').length))
				{
					$this.on('click', function(ev)
					{
						ev.preventDefault();

						_triggerWindowOpen(link);
					});
				}
				else
				{
					$this.attr('href', link);
				}
			}
		});
	});

	var _initializeAutocompleteData = (function(route, filters, clear)
	{
		if (_data && typeof(clear) !== 'undefined' && clear === true)
		{
			//console.log('Trying to clear the prefetch data');
			_data.clear();
		}

		_data = new Bloodhound(
		{
			datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
			queryTokenizer: Bloodhound.tokenizers.whitespace,
			remote: {
				url: '/' + route + '/autocomplete?' + filters + 'search=%QUERY%',
				//cache: false,
				wildcard: '%QUERY%',
				filter: function(list)
				{
					return $.map(list, function(data)
					{
						if (route === 'codes')
						{
							return {
								id: data.id,
								name: data.number,
								emails: data.emails,
								region: data.region_id,
								notes: data.notes,
							};
						}

						if (route === 'users')
						{
							return {
								id: data.id,
								name: data.first_name + ' ' + data.last_name + ' (' + data.username + ')',
								username: data.username
							};
						}

						return {
							id: data.id,
							name: data.name
						};
					});
				}
			}
		});

		_data.initialize((typeof(clear) !== 'undefined' && clear === true ? true : false));
	});

	var _getAutocompleteData = (function(selector, options)
	{
		if (!$(selector).length)
		{
			return;
		}

		var route = '';
		var value = '';
		var filters = '';

		if (typeof(options.route) === 'undefined' || !options.route.length)
		{
			return;
		}

		route = options.route;

		if (typeof(options.value) === 'undefined' || !options.value.length)
		{
			$(selector).val('');

			return;
		}

		value = options.value;

		if (typeof(options.filters) !== 'undefined' && options.filters.length)
		{
			filters = options.filters;
		}

		$.ajax(
		{
			type: 'GET',
			url: '/' + route + '/autocomplete?' + filters + 'search=' + value,
			dataType: 'json',
			//async: false,
			beforeSend: function()
			{
				$(selector).val('');
			},
			success: function(data)
			{
				if (!$.isEmptyObject(data) && typeof(data[0]) === 'object')
				{
					$(selector).val(data[0].notes);
				}
			},
			error: function(jqXHR, textStatus)
			{
			}
		});
	});

	var _getTypeaheadFilters = (function(control)
	{
		var result = '';

		if (!control)
		{
			return result;
		}

		if (typeof(control.data('filters')) !== 'undefined' && control.data('filters'))
		{
			result += 'filters=' + control.data('filters') + '&';
		}

		if (typeof(control.data('excluded-control')) !== 'undefined' && control.data('excluded-control'))
		{
			var $control = $('[name="' + control.data('excluded-control').replace('#', '') + '"]');

			if ($control && $control.length && $control.val().length)
			{
				result += 'excluded=' + $control.val() + '&';
			}
		}

		return result;
	});

	var _toggleTypeaheadFilters = (function(selector, value)
	{
		if (!$(selector).length || !value)
		{
			return;
		}

		$(selector).each(function(index)
		{
			var $this = $(this);
			var route = $this.data('route');

			// Try to remove any previously set value that might be incorrect regarding the new filter(s)
			if ($('#' + $this.attr('id') + '_selected').length && $('#' + $this.attr('id') + '_selected').val() === value)
			{
				$this.typeahead('val', '');

				$('#' + $this.attr('id') + '_selected').val('0');

				_triggerSync('#' + $this.attr('id') + '_selected');
			}

			$this.typeahead('destroy');

			var filters = _getTypeaheadFilters($this);

			_initializeAutocompleteData(route, filters, true);
		});

		App.handleTypeahead(selector, true);
	});

	var _changeTypeaheadFilters = (function(selector, value)
	{
		if (!$(selector).length || !value)
		{
			return;
		}

		$(selector).each(function(index)
		{
			var $this = $(this);
			var route = $this.data('route');

			// Try to remove any previously set value that might be incorrect regarding the new filter(s)
			if ($('#' + $this.attr('id') + '_selected').length && $('#' + $this.attr('id') + '_selected').val() > 0)
			{
				$this.typeahead('val', '');

				$('#' + $this.attr('id') + '_selected').val('0');

				if (route === 'codes')
				{
					$this.addClass('is-invalid').removeClass('is-valid');
				}

				_triggerSync('#' + $this.attr('id') + '_selected');
			}

			if (typeof($this.data('filters')) === 'undefined')
			{
				$this.attr('data-filters', '[]');
			}

			if (typeof(value) === 'object')
			{
				$this.attr('data-filters', '[' + value.join(',') + ']');
			}
			else if (!exclude || exclude === false)
			{
				$this.attr('data-filters', '[' + value + ']');
			}

			$this.typeahead('destroy');

			var filters = _getTypeaheadFilters($this);

			_initializeAutocompleteData(route, filters, true);
		});

		App.handleTypeahead(selector, true);
	});

	var _runUpdate = (function(params)
	{
		var element,
			route,
			action,
			id,
			field;
		var value = 1;

		if (typeof(params) === 'undefined' || $.isEmptyObject(params))
		{
			return false;
		}

		if (typeof(params.element) !== 'undefined' && params.element.length)
		{
			element = params.element;
		}

		if (typeof(params.route) !== 'undefined' && params.route.length)
		{
			route = params.route;
		}

		if (typeof(params.action) !== 'undefined' && params.action.length)
		{
			action = params.action;
		}

		if (typeof(params.id) !== 'undefined')
		{
			id = params.id;
		}

		if (typeof(params.field) !== 'undefined' && params.field.length)
		{
			field = params.field;
		}

		if (typeof(params.value) !== 'undefined')
		{
			value = params.value;
		}

		if (!route.length || !action.length || !id || !field.length)
		{
			return false;
		}

		$.ajax(
		{
			type: 'POST',
			url: '/' + route + '/' + action + '/' + id,
			data: 'field=' + field + '&value=' + value,
			dataType: 'json',
			//async: false,
			beforeSend: function()
			{
				var settings = {
					"timeOut": 0,
					"progressBar": false
				};

				App.showToastr('info', _getLabel('txtProcessingMessage'), _getLabel('txtProcessingTitle'), settings);

				if (element.length)
				{
					element.attr('disabled', true);
				}
			},
			success: function(data)
			{
				if (!$.isEmptyObject(data))
				{
					toastr.clear();

					var settings = {
						"timeOut": 5000,
						"progressBar": true
					};

					App.showToastr(data.status, data.message, data.title, settings);

					if (data.status === 'success')
					{
						if (route !== 'codes')
						{
							if (field === 'is_aborted' || field === 'is_completed')
							{
								_clearWorkerCache();
							}

							setInterval(function()
							{
								window.location.replace(((typeof(data.route) !== 'undefined' && data.route.length) ? data.route : '/' + route));
							}, 2000);
						}
					}
					else if (element.length)
					{
						element.attr('disabled', false);
					}

					//console.log(data.additional);
					if (typeof(data.additional) !== 'undefined')
					{
						_handleEnableAction(data.additional.enable);

						_handleDisableAction(data.additional.disable);
					}
				}
			},
			error: function(jqXHR, textStatus)
			{
				if (element.length)
				{
					element.attr('disabled', false);
				}
			}
		});
	});

	var _restrictDateTimePicker = (function(selector)
	{
		if (!selector || !selector.length)
		{
			return false;
		}

		var controls = selector.split(',');

		if (!controls.length)
		{
			return false;
		}

		var combined = '';
		var format = '';
		var offset = 120;

		$.each(controls, function(index, control)
		{
			if (!$(control).length)
			{
				return;
			}

			//console.log($(control));

			var datetime_format = $(control).datetimepicker('format');
			var datetime_value = $(control).datetimepicker('date');

			if (datetime_value)
			{
				offset = datetime_value.utcOffset();
			}

			//console.log('Control: ' + control);
			//console.log('Control format: ' + datetime_format);
			//console.log('Control value:');
			//console.log(datetime_value);

			if (datetime_value)
			{
				combined += (combined.length ? ' ' : '') + datetime_value.format(datetime_format);
				format += (format.length ? ' ' : '') + datetime_format;

				//console.log('Combined format:' + format);
				//console.log('Combined value:');
				//console.log(combined);
			}
		});

		if (!combined.length)
		{
			return false;
		}

		if (combined.indexOf(' ') === -1)
		{
			combined += ' 00:00';
		}

		if (format.indexOf(' ') === -1)
		{
			format += ' HH:mm';
		}

		//console.log('Converted format: ' + format);
		//console.log('Converted value:');

		var result = moment(combined, format);

		/*if (result.utcOffset() > offset)
		{
			result.add(((result.utcOffset() - offset) / 60), 'hours');
		}*/

		//console.log(result);
		//console.log(moment.utc(combined).utcOffset(offset));
		//console.log(offset);
		//console.log(moment(combined).utcOffset());

		return result;
	});

	var _checkPasswordStrength = (function(selector, length, string)
	{
		if (!$(selector).length)
		{
			return;
		}

		var add_classes = 'bg-danger';
		var remove_classes = 'bg-success bg-warning';

		_password_strength = 0;

		if (length >= 8 && length < 12)
		{
			add_classes = 'bg-warning';
			remove_classes = 'bg-success bg-danger';
			_password_strength = 30;
		}
		else if (length >= 12)
		{
			add_classes = 'bg-success';
			remove_classes = 'bg-warning bg-danger';
			_password_strength = 60;
		}

		$(selector).addClass(add_classes).removeClass(remove_classes);

		// Check for the character-set constraints
		// and update percentage variable as needed.

		// Lowercase characters only
		if (string.match(/[a-z]/) !== null)
		{
			_password_strength += 10;
		}

		// Uppercase characters only
		if (string.match(/[A-Z]/) !== null)
		{
			_password_strength += 10;
		}

		// Digits only
		if (string.match(/0|1|2|3|4|5|6|7|8|9/) !== null)
		{
			_password_strength += 10;
		}

		// Special characters
		if ((string.match(/\W/) !== null) && (string.match(/\D/) !== null))
		{
			_password_strength += 10;
		}

		// Update the width of the progress bar
		$(selector).css('width', _password_strength + '%');
	});

	var _toggleAttachedControls = (function(controls, state)
	{
		if ($.isEmptyObject(controls) || typeof(state) !== 'boolean')
		{
			return;
		}

		$.each(controls, function(index, control)
		{
			var $control = $(control);

			if ($control && $control.length)
			{
				$control.prop(($control.prop('tagName').toLowerCase() === 'button' ? 'disabled' : 'readonly'), state);
			}
		});
	});

	var _getConnectedDateTimeControls = (function($control)
	{
		var result = [];

		var $section = $control.parents('section');

		if (!$section.length)
		{
			return result;
		}

		var $elements = $section.find('div.datetime-picker[data-connected-controls]');

		if (!$elements.length)
		{
			return result;
		}

		var min = '';

		$elements.each(function()
		{
			var $this = $(this);
			var $control = $this.children('input[type="text"]');

			if (typeof($control.attr('disabled')) !== 'undefined' || !$control.val().length)
			{
				return;
			}

			if ($this.data('connected-controls').length)
			{
				var connected = $this.data('connected-controls').split(',');

				if (connected.length > 1)
				{
					min = '';

					for (var i = 0; i < connected.length; i++)
					{
						if (!$(connected[i]).length)
						{
							continue;
						}

						min += (min.length ? ' ' : '') + $(connected[i]).children('input[type="text"]').val();
					}
				}
				else
				{
					if ($(connected[0]).length && $(connected[0]).children('input[type="text"]').val().length)
					{
						min = $(connected[0]).children('input[type="text"]').val();
					}
				}
			}

			result.push({
				element: $control.prop('id'),
				value: $control.val(),
				min: min,
			});

			min = $control.val();
		});

		return result;
	});

	var _checkDateTimeFlow = (function($control)
	{
		var result = {
			valid: true,
			element: '',
		}

		var controls = _getConnectedDateTimeControls($control);

		if (!controls.length)
		{
			return result;
		}

		for (var i = 0; i < controls.length; i++)
		{
			var data = controls[i];

			if ($('#' + data.element).hasClass('is-invalid'))
			{
				$('#' + data.element).removeClass('is-invalid');
			}

			if (moment(data.value).isBefore(data.min))
			{
				result = {
					valid: false,
					element: '#' + data.element,
				};

				if (!$(result.element).hasClass('is-invalid'))
				{
					$(result.element).addClass('is-invalid');
				}

				break;
			}
		}

		return result;
	});

	var _attachCSPCode = (function(element)
	{
		const nonce = window.nonce;

		if (!nonce)
		{
			return;
		}

		element.querySelector('.swal2-popup').style.setProperty('nonce', nonce);
	});

	return {
		init: function()
		{
			_initializeTranslation();

			//_initializeOnlineStatus();

			App.handlePerfectScrollbar('.perfect-scrollbar, [data-perfect-scrollbar]');
			App.handleRadioButtonReset('.resettable');
			App.handleFocusNext('.focusnext');
			App.handleAddDataRow('.btn-addrow');
			App.handleRemoveDataRow('.btn-removerow');
			App.handleDatePicker('.datepicker');
			App.handleTimePicker('.timepicker');
			App.handleDateTimePicker('.datetime-picker');
			App.handleDateRangePicker('#filter_period');
			App.handleTagging('.tagBox,.emailsTags');
			App.handlePopover('[data-toggle="popover"]');
			App.handleTooltip('[data-toggle="tooltip"]');
			App.handleSelectPicker('select.selectpicker');
			App.handleMultiselect('select.multiselect[data-role="multiselect"]');
			App.handleInputSpinner('input[type="number"]');
			App.handleInputMask('input.inputmask');
			App.handleFileUpload('input.fileupload[type="file"]');
			App.handleSignatures('.signature');
			App.handleTypeahead('.typeahead');
			App.handleDatatables('#datatable');
			App.handleFormValidation('.form_validation');
			App.handleCountdown('.timer');
			App.handlePreselectedRegion('#preselected_region');
			App.handleSynchronizableControls('.sync');
			App.handleActionButton('.action');
			App.handleOpenLink('a[data-files]');

			App.handleAddAdditionalRow('.btn-add-row');
			App.handleRemoveAdditionalRow('.btn-remove-row');

			App.handleAutofillable('[data-autofilled]');

			App.handleFiltersState('.filter-state');

			_triggerControl('[data-trigger-control]');
			_showControl('[data-show-control]');
			_toggleAddDataButton('.btn-addrow');
			_toggleRemoveDataButton('.btn-removerow');

			_toggleRemoveAdditionalButton('.btn-remove-row');

			//_handleAddToHome('.add-to-home');
			_handleRemoveConfirmation('table.table form[method="POST"] > button[type="submit"]');

			//_checkOnlineStatus();

			//_initializeDatabase();

			setTimeout(function()
			{
				//_clearStoreData();
				_changeFileInputIconLink('.kv-file-content a[target="_blank"], .kv-file-content object.kv-preview-data, .kv-file-content img.kv-preview-data');
			}, 3000);
		},
		scrollTo: function(element, value, duration, animation)
		{
			var selector = element;

			if (element.indexOf('#') !== 0)
			{
				selector = '#' + selector;
			}

			if (element.indexOf('.') === 0 || element.indexOf('div') === 0 || element.indexOf('h4') === 0)
			{
				selector = element;
			}

			if ($(selector).length)
			{
				// calculate destination place
				var offset = ((typeof(value) !== "undefined") ? value : 0);
				var dest = ($(selector).offset().top - offset);

				if ($(selector).offset().top > ($(document).height() - $(window).height()))
				{
					dest = (($(document).height() - $(window).height()) - offset);
				}

				if (typeof(animation) === 'undefined')
				{
					animation = 'swing';
				}

				// go to destination
				$('html,body').animate({ scrollTop: dest }, duration, animation);
			}
		},
		handlePerfectScrollbar: function(selector)
		{
			// Perfect scrollbar
			if (!window.PerfectScrollbar || !$(selector).length)
			{
				return;
			}

			$(selector).each(function(index)
			{
				var $el = $(this);
				var ps = new PerfectScrollbar(this, {
					suppressScrollX: $el.data("suppress-scroll-x"),
					suppressScrollY: $el.data("suppress-scroll-y")
				});
			});
		},
		handleRadioButtonReset: function(selector)
		{
			if (!$(selector).length)
			{
				return;
			}

			var options = {
				clear: true,
				focus: true,
				required: true,
			};

			$(selector).each(function()
			{
				$(this).on('click', function(ev)
				{
					ev.stopPropagation();
					ev.preventDefault();

					var $control = $(this).children('input[type="radio"]');

					if (!$control.prop('readonly'))
					{
						//console.log($control.attr('name'));

						if ($control.is(':checked'))
						{
							$control.prop('checked', false);

							_toggleControls($control, options);
						}
						else
						{
							if ($control.attr('name') === 'validity_id')
							{
								var validate = _validateSection($control);

								if (validate.valid)
								{
									$control.prop('checked', true);
								}
								else
								{
									Swal.fire(
									{
										icon: 'error',
										title: _getLabel('txtErrorTitle'),
										text: _getLabel('txtValidationFailedMessage'),
										confirmButtonText: _getLabel('btnOK'),
										buttonsStyling: false,
										customClass: {
											confirmButton: 'btn btn-danger btn-lg',
										},
										onBeforeOpen: _attachCSPCode,
									}).then((result) => {
										if (result.isConfirmed)
										{
											if (validate.element.length)
											{
												if (validate.element.indexOf('_selected') !== false)
												{
													validate.element = validate.element.replace('_selected', '');
												}

												//console.log('Element to focus: ' + validate.element);

												$(validate.element).trigger('focus');

												App.scrollTo(validate.element, 140, 500);
											}
										}
									});
								}
							}
							else
							{
								$control.prop('checked', true);

								_toggleControls($control, options);
							}
						}

						//console.log($control.is(':checked'));
						//_triggerSync('#' + $control.attr('id'));
					}
				});
			});
		},
		handleFocusNext: function(selector)
		{
			if (!$(selector).length)
			{
				return;
			}

			$(selector).each(function()
			{
				var $this = $(this);
				var $control = $this.children('input[type="checkbox"],input[type="radio"]');
				var $next = $this.next('input[type="text"]');

				if ($next.length)
				{
					$this.on('click', function()
					{
						if (!$control.prop('readonly'))
						{
							if ($control.is(':checked'))
							{
								$next.attr('readonly', true).val('');

								_triggerSync('#' + $next.attr('id'));
							}
							else
							{
								$next.attr('readonly', false).trigger('focus');
							}
						}
					});
				}
			});
		},
		handleAddDataRow: function(selector)
		{
			if (!$(selector).length)
			{
				return;
			}

			$(selector).on('click', function()
			{
				var $this = $(this);
				var $row = $this.closest('.row');
				var $section = $this.parents('[id]');
				var rows = {};

				if (!$section.length)
				{
					return;
				}

				var max_records = _getNumberOfRecords('max', $section);
				var total_records = _getNumberOfRecords('total', $section, 2);
				var state = '';

				if (typeof($section.data('columns')) !== 'undefined')
				{
					rows = $section.data('columns');
				}

				if (typeof($section.data('state')) !== 'undefined')
				{
					state = ' ' + $section.data('state');
				}

				if (!$.isEmptyObject(rows))
				{
					var next_index = (total_records + 1);

					var element = '<div class="row">';

					$.each(rows, function(index, value)
					{
						element += '<div class="col-md-' + ((index == 'location') ? '6' : '3') + '"><div class="form-group mb-3"><input id="alarm_' + index + '_' + next_index + '" name="alarms[' + index + '][' + next_index + ']" type="text" placeholder="' + value + ' ' + next_index + '"' + state + ' class="form-control form-control-lg"></div></div>';
					});

					element += '</div>';

					$(element).insertBefore($row);

					_toggleAddDataButton('.btn-addrow');
					_toggleRemoveDataButton('.btn-removerow');
				}
			});
		},
		handleRemoveDataRow: function(selector)
		{
			if (!$(selector).length)
			{
				return;
			}

			$(selector).on('click', function()
			{
				var $this = $(this);
				var $row = $this.closest('.row');
				var $prev = $row.prev('.row');
				var $section = $this.parents('[id]');

				if (!$section.length)
				{
					return;
				}

				var total_records = _getNumberOfRecords('total', $section, 2);

				if (total_records > 0 && $prev.length)
				{
					$prev.remove();

					_toggleAddDataButton('.btn-addrow');
					_toggleRemoveDataButton('.btn-removerow');
				}
			});
		},
		handleDatePicker: function(selector)
		{
			if (!$(selector).length)
			{
				return;
			}

			$(selector).pickadate(
			{
				format: 'yyyy-mm-dd'
			});
		},
		handleTimePicker: function(selector)
		{
			if (!$(selector).length)
			{
				return;
			}

			$(selector).pickatime(
			{
				format: 'H:i',
				interval: 15,
			});
		},
		handleDateTimePicker: function(selector)
		{
			if (!$(selector).length)
			{
				return;
			}

			$(selector).each(function()
			{
				var $this = $(this);
				////console.log('Current control:');
				////console.log($this);

				var datetime_format = 'YYYY-MM-DD HH:mm';
				var datetime_mode = 'days';
				var datetime_timezone = '';
				var datetime_default = null;
				var control = '';
				var check_controls = [];

				if (typeof($this.data('format')) !== 'undefined')
				{
					datetime_format = $this.data('format');
				}

				if (typeof($this.data('mode')) !== 'undefined')
				{
					datetime_mode = $this.data('mode');
				}

				if (typeof($this.parents('form').data('timezone')) !== 'undefined')
				{
					datetime_timezone = $this.parents('form').data('timezone');
				}

				if (typeof($this.data('trigger-control')) !== 'undefined' && $this.data('trigger-control').length)
				{
					control = $this.data('trigger-control');
				}

				if (typeof($this.data('check-controls')) !== 'undefined' && $this.data('check-controls').length)
				{
					check_controls = $this.data('check-controls').split(',');
				}

				if ($this.find('input[type="text"]').length && $this.find('input[type="text"]').val().length)
				{
					datetime_default = moment($this.find('input[type="text"]').val(), datetime_format);
					//datetime_default = $this.find('input[type="text"]').val();
					////console.log('Default datetime');
					////console.log(datetime_default);
					//console.log(datetime_default.utcOffset());
					////console.log('Has init value: ' + datetime_default);
				}

				var options = {
					format: datetime_format,
					viewMode: datetime_mode,
					timeZone: datetime_timezone,
					locale: _getLabel('optLanguage'),
					icons: {
						time: "fal fa-clock",
						date: "fal fa-calendar",
						up: "fal fa-arrow-up",
						down: "fal fa-arrow-down"
					},
					showTodayButton: true,
					useCurrent: false,
					keepOpen: false
				};

				if (typeof($this.data('connected-controls')) !== 'undefined' && $this.data('connected-controls').length)
				{
					////console.log('Connected control (init)');
					var min_date = _restrictDateTimePicker($this.data('connected-controls'));
					////console.log('Min datetime');
					////console.log(min_date);
					//console.log(min_date.utcOffset());

					if (min_date && min_date.isValid())
					{
						options['minDate'] = min_date;

						////console.log('Min date: ' + min_date);
						/*if (datetime_default)
						{
							if (min_date > datetime_default)
							{
								////console.log('Min date is after the initial one?!');
							}
							options['defaultDate'] = datetime_default;
						}*/

						////console.log(options);
					}
				}

				$this.datetimepicker(options);

				_toggleAttachedControls(check_controls, !!$this.datetimepicker('date'));

				if (control.length && $(control).length)
				{
					var control_format = datetime_format;

					if (typeof($(control).data('format')) !== 'undefined')
					{
						control_format = $(control).data('format');
					}
				}

				////console.log('Datetimepicker control');
				////console.log($this);
				//console.log('Datetimepicker trigger button');
				//console.log($this.find('button[type="button"]'));
				$this.find('button[type="button"]').on('click', function()
				{
					if ($this.children('input[type="text"]').prop('disabled'))
					{
						return;
					}

					var current_date = $this.datetimepicker('date');
					var min_date = $this.datetimepicker('minDate');

					if (!current_date)
					{
						current_date = moment(); //.utc();
					}

					//console.log('Now: ' + current_date);
					//console.log('minDate: ' + min_date);

					if (min_date > current_date)
					{
						current_date = min_date;
					}

					$this.datetimepicker('format', datetime_format);
					$this.datetimepicker('date', current_date);
					$this.datetimepicker('viewDate', current_date);

					if (control.length && $(control).length)
					{
						$(control).datetimepicker('format', control_format);
						$(control).datetimepicker('date', current_date);
						$(control).datetimepicker('viewDate', current_date);
						$(control).trigger('blur');
					}
				});

				$this.on('change.datetimepicker update.datetimepicker', function(e)
				{
					////console.log('Change/Update event');

					if (!e.date)
					{
						e.date = e.target.value;
					}

					////console.log('Parameters:');
					////console.log(e);
					////console.log(e.date);

					if (e.date && control.length && $(control).length)
					{
						$(control).datetimepicker('format', control_format);
						$(control).datetimepicker('date', e.date);
						$(control).datetimepicker('viewDate', e.date);
						$(control).trigger('blur');
					}

					if ($(selector + '[data-connected-controls*="#' + $this.attr('id') + '"]').length)
					{
						$(selector + '[data-connected-controls*="#' + $this.attr('id') + '"]').each(function()
						{
							////console.log('Connected control (change/update)');
							////console.log($(this));
							var min_date = _restrictDateTimePicker($(this).data('connected-controls'));
							////console.log(min_date);

							if (min_date)
							{
								$(this).datetimepicker('minDate', min_date);
							}
						});
					}

					_toggleAttachedControls(check_controls, !!e.date);
				});

				$this.find('input[type="text"]').on('blur', function()
				{
					$this.datetimepicker('hide');
				});
			});
		},
		handleDateRangePicker: function(selector)
		{
			if (!$(selector).length)
			{
				return null;
			}

			var start = moment();
			var end = moment();

			if (typeof($(selector).data('start')) !== 'undefined' && $(selector).data('start').length && moment($(selector).data('start')).isValid())
			{
				start = moment($(selector).data('start'));
			}

			if (typeof($(selector).data('end')) !== 'undefined' && $(selector).data('end').length && moment($(selector).data('end')).isValid())
			{
				end = moment($(selector).data('end'));
			}

			var options = {
				startDate: start,
				endDate: end,
				ranges: _getLabel('drpRanges'),
				locale: _getLabel('drpLocale'),
			};

			function cb(start, end)
			{
				if ($(selector + ' span').length)
				{
					$(selector + ' span').html(start.format('D MMMM YYYY') + ' - ' + end.format('D MMMM YYYY'));
				}
			}

			if ($(selector).next('.input-group-append').length)
			{
				$(selector).next('.input-group-append').find('button').on('click', function (ev)
				{
					$(selector).data('daterangepicker').toggle();
				});
			}

			$(selector).daterangepicker(options, cb);

			cb(start, end);
		},
		handleTagging: function(selector)
		{
			if (!$(selector).length)
			{
				return null;
			}

			var control = $(selector).data('trigger-control');
			//var required = (typeof($(selector).data('rule-required')) !== 'undefined' && $(selector).data('rule-required') == true);

			var $tagging = $(selector).tagging();
			var tagging = $tagging[0];

			/*if (required && tagging.find('.type-zone').length)
			{
				tagging.find('.type-zone').attr('name', 'type_zone');
				tagging.find('.type-zone').attr('data-rule-required', required);
			}*/

			// Execute callback when a tag is added
			tagging.on('add:after', function(element, text, tag)
			{
				if (control && control.length)
				{
					if (tag.tags.length)
					{
						if ($(control + '_yes').length)
						{
							$(control + '_yes').parent('label').trigger('click');
						}
					}
					else
					{
						if ($(control + '_no').length)
						{
							$(control + '_no').parent('label').trigger('click');
						}
					}
				}

				if (!$(selector).prop('readonly'))
				{
					_triggerSync('#' + $(selector).attr('id'), tagging.tagging('getTags'));
				}
			});

			// Execute callback when a tag is removed
			tagging.on('remove:after', function(element, text, tag)
			{
				if (control && control.length)
				{
					if (tag.tags.length)
					{
						if ($(control + '_yes').length)
						{
							$(control + '_yes').parent('label').trigger('click');
						}
					}
					else
					{
						if ($(control + '_no').length)
						{
							$(control + '_no').parent('label').trigger('click');
						}
					}
				}

				if (tagging.find('.type-zone').length)
				{
					tagging.find('.type-zone').val(tagging.find('.type-zone').val().replace(text, ''));
				}

				if (!$(selector).prop('readonly'))
				{
					_triggerSync('#' + $(selector).attr('id'), tagging.tagging('getTags'));
				}
			});

			return $(selector);
		},
		handlePopover: function(selector)
		{
			if (!$(selector).length)
			{
				return;
			}

			$(selector).popover();
		},
		handleTooltip: function(selector)
		{
			if (!$(selector).length)
			{
				return;
			}

			$(selector).tooltip();
		},
		handleSelectPicker: function(selector)
		{
			if (!$(selector).length)
			{
				return;
			}

			$(selector).selectpicker(
			{
				container: 'body',
				width: '100%',
			});
		},
		handleMultiselect: function(selector)
		{
			if (!$(selector).length)
			{
				return;
			}

			$(selector).multiselect(
			{
				includeSelectAllOption: true,
				numberDisplayed: 6
			});
		},
		handleInputSpinner: function(selector)
		{
			if (!$(selector).length)
			{
				return;
			}

			var options = {
				width: "4.2rem",
				decrement: "fal fa-minus fa-lg",
				increment: "fal fa-plus fa-lg",
				group: "input-group-xl",
				class: "btn-dark",
			}

			if (typeof($(selector).data('button-width')) !== 'undefined')
			{
				options.width = $(selector).data('button-width');
			}

			if (typeof($(selector).data('decrement-class')) !== 'undefined')
			{
				options.decrement = $(selector).data('decrement-class');
			}

			if (typeof($(selector).data('increment-class')) !== 'undefined')
			{
				options.increment = $(selector).data('increment-class');
			}

			if (typeof($(selector).data('group-class')) !== 'undefined')
			{
				options.group = $(selector).data('group-class');
			}

			if (typeof($(selector).data('button-class')) !== 'undefined')
			{
				options.class = $(selector).data('button-class');
			}

			$(selector).inputSpinner(
			{
				buttonsWidth: options.width,
				decrementButton: "<i class='" + options.decrement + "'></i>",
				incrementButton: "<i class='" + options.increment + "'></i>",
				groupClass: options.group,
				buttonsClass: options.class,
			});

			$(selector).on('change', function(ev)
			{
				var $this = $(this);

				if (typeof($this.data('trigger-control')) !== 'undefined' && $this.data('trigger-control').length && $($this.data('trigger-control')).length)
				{
					$($this.data('trigger-control')).val($this.val());
				}

				if (!$this.prop('readonly'))
				{
					_triggerSync('#' + $this.attr('id'));
				}
			})
		},
		handleInputMask: function(selector)
		{
			if (!$(selector).length)
			{
				return;
			}

			var mask = '99 999 999 999';

			$(selector).each(function()
			{
				var $this = $(this);

				if (typeof($this.data('mask')) !== 'undefined' && $this.data('mask').length)
				{
					mask = $this.data('mask');
				}

				$this.inputmask({
					mask: mask,
				});
			});
		},
		handleFileUpload: function(selector)
		{
			if (!$(selector).length)
			{
				return;
			}

			$(selector).each(function()
			{
				var $this = $(this);
				var id = $this.attr('id');
				var is_readonly = (typeof($(this).attr('readonly')) !== 'undefined' && $(this).attr('readonly') !== false);

				var theme = 'fa';
				var type = 'document';
				var allowed_extensions = ['jpg', 'jpeg', 'gif', 'png', 'doc', 'docs', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf'];
				var init_preview = [];
				var init_preview_config = [];

				if (typeof($this.data('theme')) !== 'undefined' && $this.data('theme').length)
				{
					theme = $this.data('theme');
				}

				if (typeof($this.data('type')) !== 'undefined' && $this.data('type').length)
				{
					type = $this.data('type');
				}

				if (typeof($this.data('allowed-extensions')) !== 'undefined' && $this.data('allowed-extensions').length)
				{
					allowed_extensions = $this.data('allowed-extensions').split(',');
				}

				if (typeof($this.data('init-preview')) !== 'undefined' && $this.data('init-preview').length)
				{
					init_preview = $this.data('init-preview');
				}

				if (typeof($this.data('init-preview-config')) !== 'undefined' && $this.data('init-preview-config').length)
				{
					init_preview_config = $this.data('init-preview-config');
				}

				var options = {
					'theme': theme,
					'showRemove': false,
					'showUpload': false,
					'showCancel': false,
					'showClose': false,
					'dropZoneEnabled': false,
					'allowedFileExtensions': ['jpg', 'jpeg', 'gif', 'png'],
					'fileActionSettings': {
						'showUpload': false,
						'showDownload': false,
						'showRemove': false,
						'showZoom': false,
						'showDrag': false,
					},
					'previewClass': $this.data('preview-class'),
					'msgPlaceholder': $this.data('placeholder'),
					'browseLabel': $this.data('button'),
				}

				if (theme !== 'fa')
				{
					options = {
						'theme': theme,
						'uploadExtraData': function()
						{
							return {
								id: $('input[name="id"]').val(),
								type: type,
								_token: $('input[name="_token"]').val(),
							};
						},
						'uploadAsync': true,
						'browseOnZoneClick': true,
						'minFileCount': 1,
						'maxFileCount': 10,
						'maxFileSize': 100000,
						'removeFromPreviewOnError': true,
						'overwriteInitial': false,
						'initialPreviewAsData': true,
						'initialPreview': init_preview,
						'initialPreviewConfig': init_preview_config,
						'previewFileIcon': '<i class="fas fa-file"></i>',
						'showCaption': false,
						'showBrowse': false,
						'showRemove': false,
						'showUpload': false,
						'showCancel': false,
						'showClose': false,
						'dropZoneEnabled': true,
						'dropZoneTitle': $this.data('placeholder'),
						'dropZoneClickTitle': _getLabel('fiDropZoneClickTitle'),
						'allowedFileExtensions': allowed_extensions,
						'fileActionSettings': {
							'showUpload': false,
							'showDownload': true,
							'showRemove': (!is_readonly),
							'showZoom': false,
							'showDrag': false,
							'downloadIcon': '<i class="fas fa-search fa-lg fa-fw"></i>',
							'downloadClass': 'btn btn-success',
							'removeIcon': '<i class="fas fa-trash-alt fa-lg fa-fw text-default"></i>',
							'removeClass': 'btn btn-warning',
						},
						'previewClass': $this.data('preview-class'),
						'msgPlaceholder': $this.data('placeholder'),
						'browseLabel': $this.data('button'),
						'preferIconicPreview': true,
						'previewFileIconSettings': {
							'doc': '<i class="fas fa-file-word text-primary"></i>',
							'xls': '<i class="fas fa-file-excel text-success"></i>',
							'ppt': '<i class="fas fa-file-powerpoint text-danger"></i>',
							'pdf': '<a href="javascript:;" target="_blank"><i class="fas fa-file-pdf text-danger"></i></a>',
							'jpg': '<a href="javascript:;" target="_blank"><i class="fas fa-file-image text-dark"></i></a>',
							'jpeg': '<a href="javascript:;" target="_blank"><i class="fas fa-file-image text-dark"></i></a>',
							'gif': '<a href="javascript:;" target="_blank"><i class="fas fa-file-image text-muted"></i></a>',
							'png': '<a href="javascript:;" target="_blank"><i class="fas fa-file-image text-primary"></i></a>'
						},
						'previewFileExtSettings': {
							'doc': function(ext)
							{
								return ext.match(/(doc|docx)$/i);
							},
							'xls': function(ext)
							{
								return ext.match(/(xls|xlsx)$/i);
							},
							'ppt': function(ext)
							{
								return ext.match(/(ppt|pptx)$/i);
							},
						}
					}

					if (type === 'photo')
					{
						options = {
							'theme': theme,
							'uploadExtraData': function()
							{
								return {
									id: $('input[name="id"]').val(),
									type: type,
									_token: $('input[name="_token"]').val(),
								};
							},
							'uploadAsync': true,
							'browseOnZoneClick': true,
							'minFileCount': 1,
							'maxFileCount': 10,
							'maxFileSize': 100000,
							'removeFromPreviewOnError': true,
							'overwriteInitial': false,
							'initialPreviewAsData': true,
							'initialPreview': init_preview,
							'initialPreviewConfig': init_preview_config,
							'showCaption': false,
							'showBrowse': false,
							'showRemove': false,
							'showUpload': false,
							'showCancel': false,
							'showClose': false,
							'dropZoneEnabled': true,
							'dropZoneTitle': $this.data('placeholder'),
							'dropZoneClickTitle': _getLabel('fiDropZoneClickTitle'),
							'allowedFileExtensions': allowed_extensions,
							'fileActionSettings': {
								'showUpload': false,
								'showDownload': true,
								'showRemove': (!is_readonly),
								'showZoom': false,
								'showDrag': false,
								'downloadIcon': '<i class="fas fa-search fa-lg fa-fw"></i>',
								'downloadClass': 'btn btn-success',
								'removeIcon': '<i class="fas fa-trash-alt fa-lg fa-fw text-default"></i>',
								'removeClass': 'btn btn-warning',
							},
							'previewClass': $this.data('preview-class'),
							'msgPlaceholder': $this.data('placeholder'),
							'browseLabel': $this.data('button'),
							'preferIconicPreview': false,
						}
					}
				}

				if (type === 'import')
				{
					options = {
						'theme': theme,
						'showRemove': false,
						'showUpload': false,
						'showCancel': false,
						'showClose': false,
						'showPreview': false,
						'dropZoneEnabled': false,
						'allowedFileExtensions': allowed_extensions,
						'fileActionSettings': {
							'showUpload': false,
							'showDownload': false,
							'showRemove': false,
							'showZoom': false,
							'showDrag': false,
						},
						'previewClass': $this.data('preview-class'),
						'msgPlaceholder': $this.data('placeholder'),
						'browseLabel': $this.data('button'),
						'maxFileSize': 10000000,
						'removeFromPreviewOnError': true,
						'overwriteInitial': false,
						'preferIconicPreview': false,
					}
				}

				var $fileinput = $this.fileinput(options);

				if (theme !== 'fa')
				{
					$fileinput.on('filebatchselected', function(ev, files)
					{
						$this.fileinput('upload');
					});

					if ($('#' + id + 'ed').length)
					{
						$fileinput.on('fileuploaded', function(ev, data, previewId, index, fileId)
						{
							var value = $('#' + id + 'ed').val();

							var selected = value.split(',');
							var index = selected.indexOf(fileId);

							if (index === -1)
							{
								selected.splice(-1, 0, fileId);
							}

							$('#' + id + 'ed').val(selected.join(','));
						});

						$fileinput.on('filedeleted', function(ev, key, jqXHR, data)
						{
							var value = $('#' + id + 'ed').val();

							var selected = value.split(',');
							var index = selected.indexOf(key);

							if (index > -1)
							{
								selected.splice(index, 1);
							}

							$('#' + id + 'ed').val(selected.join(','));
						});

						$fileinput.on('filebatchuploadcomplete', function(event, files, extra)
						{
							_changeFileInputIconLink('.kv-file-content a[target="_blank"], .kv-file-content object.kv-preview-data, .kv-file-content img.kv-preview-data');
						});
					}
				}
			});
		},
		handleSignatures: function(selector)
		{
			if (!$(selector).length)
			{
				return;
			}

			var signatures = [];
			var content = [];

			$(selector).each(function()
			{
				var $this = $(this);
				var type = $this.data('type');
				var route = $this.data('route');

				if (typeof($this.data('init')) !== 'undefined')
				{
					content.push($this.data('init'));
				}

				if ($this.find('canvas').length)
				{
					var canvas = $this.find('canvas')[0];

					var signature = new SignaturePad(canvas, {
						minWidth: 1,
						maxWidth: 5,
						penColor: 'rgb(0, 0, 0)'
					});

					signatures.push({ 'element': $this, 'canvas': canvas, 'signature': signature });
				}

				if ($this.find('button.btn-fullscreen').length)
				{
					$this.find('button.btn-fullscreen').on('click', function(ev)
					{
						$this.toggleClass('fullscreen');
					})
				}

				if ($this.find('button.btn-clear').length)
				{
					$this.find('button.btn-clear').on('click', function(ev)
					{
						signature.clear();
					})
				}

				if ($this.find('button.btn-save').length)
				{
					$this.find('button.btn-save').on('click', function(ev)
					{
						_ajaxSetup();

						var image = signature.toDataURL();

						if (!signature.isEmpty() && image.length)
						{
							$.ajax(
							{
								type: 'POST',
								url: '/' + route + '/save',
								data: 'id=' + $('input[name="id"]').val() + '&type=' + type + '&_token=' + $('input[name="_token"]').val() + '&image=' + encodeURIComponent(image),
								dataType: 'json',
								async: false,
								beforeSend: function()
								{
									$this.removeClass('fullscreen');
								},
								success: function(data)
								{
									var settings = {
										"timeOut": 5000,
										"progressBar": true
									};

									if (data.data.length && $this.find('input[type="hidden"]').length)
									{
										$this.find('input[type="hidden"]').val(data.data);

										signature.off();

										if ($this.find('button.btn-fullscreen').length)
										{
											$this.find('button.btn-fullscreen').addClass('d-none');
										}

										if ($this.find('button.btn-clear').length)
										{
											$this.find('button.btn-clear').addClass('d-none');
										}

										if ($this.find('button.btn-save').length)
										{
											$this.find('button.btn-save').addClass('d-none');
										}
									}

									App.showToastr(data.type, data.message, data.title, settings);
								},
							});
						}
					});
				}
			});

			if (!$.isEmptyObject(signatures))
			{
				function resizeCanvas()
				{
					var ratio =  Math.max(window.devicePixelRatio || 1, 1);

					$.each(signatures, function(index, value)
					{
						value.canvas.width = value.canvas.offsetWidth * ratio;
						value.canvas.height = value.canvas.offsetHeight * ratio;
						value.canvas.getContext("2d").scale(ratio, ratio);
						value.signature.clear(); // otherwise isEmpty() might return incorrect value

						if (!$.isEmptyObject(content) && typeof(content[index]) !== 'undefined' && content[index])
						{
							value.signature.fromDataURL(content[index]);
						}
					});
				}

				window.addEventListener("resize", resizeCanvas);
				resizeCanvas();
			}

			if (!$.isEmptyObject(content))
			{
				$.each(content, function(index, value)
				{
					//console.log('Content index: ' + index);
					//console.log('Content value: ' + value);
					//console.log('Signatures');
					//console.log(signatures);

					if (value && signatures[index])
					{
						var $this = signatures[index].element;

						signatures[index].signature.fromDataURL(value);

						signatures[index].signature.off();

						if ($this.find('button.btn-fullscreen').length)
						{
							$this.find('button.btn-fullscreen').addClass('d-none');
						}

						if ($this.find('button.btn-clear').length)
						{
							$this.find('button.btn-clear').addClass('d-none');
						}

						if ($this.find('button.btn-save').length)
						{
							$this.find('button.btn-save').addClass('d-none');
						}
					}
				});
			}
		},
		handleTypeahead: function(selector, clear)
		{
			if (!$(selector).length)
			{
				return;
			}

			_ajaxSetup();

			localStorage.clear();

			//var $emailsTags = App.handleTagging('.emailsTags');
			var $emailsTags = $('.emailsTags');

			$(selector).each(function()
			{
				var $this = $(this);
				var $parent = $this.parent();

				var route = $this.data('route');
				var min_length = parseInt($this.data('min-length'));
				var is_required = ($this.data('required'));
				var filters = _getTypeaheadFilters($this);

				_initializeAutocompleteData(route, filters, clear);

				if ($this.prop('readonly'))
				{
					return;
				}

				var $typeahead = $this.typeahead(
				{
					minLength: min_length,
					hint: true,
					highlight: true
				},
				{
					name: '_data',
					displayKey: 'id',
					source: _data.ttAdapter(),
					limit: Infinity,
					display: function(item)
					{
						return item.name;
					}
				});

				switch (route)
				{
					case 'codes':
						if (!$this.typeahead('val').length)
						{
							$this.addClass('is-invalid');

							if (is_required)
							{
								$this.addClass('mandatory');
							}
						}

						var options = {
							route: route,
							filters: filters,
							value: '',
						};

						$this.on('change', function(ev)
						{
							//console.log('onchange event');
							//console.log(ev);
							//console.log($this.typeahead('val'));

							options.value = $this.typeahead('val');

							_getAutocompleteData('#' + route + '_notes', options);

							if ($this.typeahead('val').length)
							{
								$this.addClass('is-valid').removeClass('is-invalid');

								if (is_required)
								{
									$this.removeClass('mandatory');
								}

								if ($('#' + $this.attr('id') + '_selected').length)
								{
									$('#' + $this.attr('id') + '_selected').val($this.typeahead('val'));
								}
							}
							else
							{
								$this.addClass('is-invalid').removeClass('is-valid');

								if (is_required)
								{
									$this.addClass('mandatory');
								}

								if ($('#' + $this.attr('id') + '_selected').length)
								{
									$('#' + $this.attr('id') + '_selected').val('0');
								}

								if ($emailsTags)
								{
									$emailsTags.tagging('removeAll');
								}
							}

							if (!$this.prop('readonly'))
							{
								//console.log('Not readonly');
								_triggerSync('#' + $this.attr('id') + '_selected');
							}
						});

						$this.on('typeahead:select', function(ev, suggestion)
						{
							//console.log('typeahead:select event');
							//console.log(ev);
							//console.log(suggestion);

							options.value = suggestion.name;

							_getAutocompleteData('#' + route + '_notes', options);

							if ($this.hasClass('is-invalid'))
							{
								$this.addClass('is-valid').removeClass('is-invalid');

								if (is_required)
								{
									$this.removeClass('mandatory');
								}
							}

							if ($('#' + $this.attr('id') + '_selected').length)
							{
								$('#' + $this.attr('id') + '_selected').val(suggestion.id);
							}

							if (!$this.prop('readonly'))
							{
								//console.log('Not readonly');
								_triggerSync('#' + $this.attr('id') + '_selected');
							}

							/*if ($(selector + '[data-route="users"]').length)
							{
								$(selector + '[data-route="users"]').attr('data-filters', '[' + suggestion.region + ']');

								App.handleTypeahead(selector + '[data-route="users"]');
							}*/

							var emails = suggestion.emails.split(';');

							if (emails.length && $emailsTags)
							{
								$emailsTags.tagging('removeAll');

								$emailsTags.tagging('add', emails);
							}
						});

						break;
					case 'users':
						var control = '';
						var $control;
						var options = {
							clear: true,
							focus: true,
							required: true,
						};

						if (typeof($this.data('trigger-control')) !== 'undefined' && $this.data('trigger-control').length)
						{
							control = $this.data('trigger-control');
						}

						if (control.length && $(control).length)
						{
							$control = $(control);
						}

						if (!$this.typeahead('val').length)
						{
							if (is_required)
							{
								$this.addClass('mandatory');
							}
						}

						$this.on('change', function(ev)
						{
							if (!$this.typeahead('val').length)
							{
								if (is_required)
								{
									$this.addClass('mandatory');
								}

								if ($('#' + $this.attr('id') + '_selected').length)
								{
									$('#' + $this.attr('id') + '_selected').val('0');
								}
							}
							else if (is_required)
							{
								$this.removeClass('mandatory');
							}

							_toggleControls($this, options);

							if ($control && $control.length)
							{
								$control.typeahead('val', $this.typeahead('val'));

								if ($('#' + $control.attr('id') + '_selected').length)
								{
									$('#' + $control.attr('id') + '_selected').val($('#' + $this.attr('id') + '_selected').val());
								}
							}

							if (!$this.prop('readonly'))
							{
								_triggerSync('#' + $this.attr('id') + '_selected');

								if (typeof($this.data('excluded-control')) !== 'undefined' && $this.data('excluded-control').length && $($this.data('excluded-control')).length)
								{
									_toggleTypeaheadFilters($this.data('excluded-control'), $('#' + $this.attr('id') + '_selected').val());
								}
							}
						});

						$this.on('typeahead:select', function(ev, suggestion)
						{
							if (is_required)
							{
								$this.removeClass('mandatory');
							}

							if ($('#' + $this.attr('id') + '_selected').length)
							{
								$('#' + $this.attr('id') + '_selected').val(suggestion.id);
							}

							_toggleControls($this, options);

							if ($control && $control.length)
							{
								$control.typeahead('val', $this.typeahead('val'));

								if ($('#' + $control.attr('id') + '_selected').length)
								{
									$('#' + $control.attr('id') + '_selected').val($('#' + $this.attr('id') + '_selected').val());
								}
							}

							if (!$this.prop('readonly'))
							{
								_triggerSync('#' + $this.attr('id') + '_selected');

								if (typeof($this.data('excluded-control')) !== 'undefined' && $this.data('excluded-control').length && $($this.data('excluded-control')).length)
								{
									_toggleTypeaheadFilters($this.data('excluded-control'), $('#' + $this.attr('id') + '_selected').val());
								}
							}
						});

						break;
				}
			});
		},
		handleDatatables: function(selector)
		{
			if (!$(selector).length)
			{
				return;
			}

			var $table = $(selector);

			var menu = true;
			var save = false;
			var records = 50;
			var total = 100;
			var exportable = false;
			var ajaxify = false;
			var exact_search = false;
			var route = '';

			var filter_period_start = '';
			var filter_period_end = '';
			var filter_state = '';

			if (typeof($table.data('clear')) !== 'undefined' && $table.data('clear') === true)
			{
				localStorage.clear();
			}

			if (typeof($table.data('save')) !== 'undefined' && $table.data('save') === true)
			{
				save = true;
			}

			if (typeof($table.data('records')) !== 'undefined' && parseInt($table.data('records')))
			{
				records = parseInt($table.data('records'));
			}

			if (typeof($table.data('total')) !== 'undefined' && parseInt($table.data('total')))
			{
				total = parseInt($table.data('total'));
			}

			if (typeof($table.data('exportable')) !== 'undefined' && $table.data('exportable') === true)
			{
				exportable = true;
			}

			if (typeof($table.data('ajaxify')) !== 'undefined' && $table.data('ajaxify') === true)
			{
				ajaxify = true;
			}

			if (typeof($table.data('exact-search')) !== 'undefined' && $table.data('exact-search') === true)
			{
				exact_search = true;
			}

			if (typeof($table.data('route')) !== 'undefined' && $table.data('route').length)
			{
				route = $table.data('route').replace('.', '/');
			}

			if (typeof($table.data('filter-period-start')) !== 'undefined' && $table.data('filter-period-start').length)
			{
				filter_period_start = $table.data('filter-period-start');
			}

			if (typeof($table.data('filter-period-end')) !== 'undefined' && $table.data('filter-period-end').length)
			{
				filter_period_end = $table.data('filter-period-end');
			}

			if (typeof($table.data('filter-state')) !== 'undefined')
			{
				filter_state = $table.data('filter-state');
			}

			var settings = _handleColumns($table);

			var dt_settings = {
				stateSave: save, // should be checked for variable 'save'
				responsive: false, // was true
				language: _getLabel('dtLanguage'),
				"lengthChange": menu,
				"lengthMenu": [
					[records, 100, 200, -1],
					[records, 100, 200, "All"]
				],
				//"dom": '<"top"<"row"<"col-md-6 col-xs-12"' + (exportable ? 'B' : '') + 'l><"col-md-6 col-xs-12"pf>>>rt<"bottom"<"row"<"col-md-5 col-xs-12"i><"col-md-7 col-xs-12"p>>>',
				"pageLength": records,
				"pagingType": 'full_numbers',
				buttons: settings.buttons,
				"columnDefs": settings.columnDefs,
				"order": settings.order,
				"autoWidth": true,
				scrollY: false,
				scrollX: true,
				scrollCollapse: true,
				//fixedColumns:   {
					//leftColumns: 1,
					//rightColumns: 1
				//},
				"drawCallback": function(settings)
				{
					var api = this.api();
					var info = api.page.info();
					var parent = this.parents(selector + '_wrapper');

					if (parent.find('.dataTables_paginate').length)
					{
						parent.find('.dataTables_paginate').each(function()
						{
							if (info.pages == 1)
							{
								if (!$(this).hasClass('d-none'))
								{
									$(this).addClass('d-none');
								}
							}
							else
							{
								if($(this).hasClass('d-none'))
								{
									$(this).removeClass('d-none');
								}
							}
						});
					}

					if (ajaxify)
					{
						App.handleCountdown('.timer');
						App.handlePopover('[data-toggle="popover"]');
						App.handleTooltip('[data-toggle="tooltip"]');
						App.handleOpenLink('a[data-files]');
						App.scrollTo(selector, 140, 500);

						_handleRemoveConfirmation('table.table form[method="POST"] > button[type="submit"]');
					}
				}
			};

			if(ajaxify)
			{
				dt_settings["processing"] = true;
				dt_settings["serverSide"] = true;
				dt_settings["ajax"] = {
					'url': '/' + route + '/ajax',
					'data': {
						'period_start': filter_period_start,
						'period_end': filter_period_end,
						'state': filter_state,
					}
				};
				dt_settings["deferRender"] = true;
				dt_settings["columns"] = settings.columns;
			}

			//console.log(dt_settings);

			var $datatable = $table.DataTable(dt_settings);

			$table.find('tbody').on('click', 'tr', function(ev)
			{
				var $cell = $(ev.target).closest('td');
				var cells = ($(this).find('td').length - 1);

				if ($cell.index() !== cells)
				{
					ev.preventDefault();

					if ($(this).find('td > a.btn[href]:first').length)
					{
						window.location = $(this).find('td > a.btn:first').attr('href');
					}

					if ($(this).find('td > .actions > a.btn[href]:first').length)
					{
						window.location = $(this).find('td > .actions > a.btn:first').attr('href');
					}
				}
			});

			if (exact_search)
			{
				$table.parents('.dataTables_wrapper').find('.dataTables_filter input').off().on('keyup', function()
				{
					var searchTerm = this.value.toLowerCase(),
						regex = '\\b' + searchTerm + '\\b';

					$datatable.rows().search(regex, true, false).draw();
				});
			}

			if (!ajaxify)
			{
				$table.on('page.dt', function()
				{
					App.scrollTo(selector, 140, 500);
				});
			}
		},
		handleFormValidation: function(selector)
		{
			if (!$(selector).length)
			{
				return;
			}
////console.log(selector);

			$(selector).each(function(index, element)
			{
				var $this = $(this);
				////console.log($this);

				if ($this.find('input[name="password"]').length)
				{
					$.validator.addMethod("pwdstrength", function(value, element)
					{
						var count = 0;

						if (/[a-z]+/.test(value))
						{
							count++;
						}

						if (/[A-Z]+/.test(value))
						{
							count++;
						}

						if (/[0-9]+/i.test(value))
						{
							count++;
						}

						if (/[`~!@#$%^&*()\-_=+|.<,>\/?;:[{\]}ç£¦¢öüä]+/i.test(value))
						{
							count++;
						}

						return this.optional(element) || (value.length >= 8 && count >= 3);
						//return this.optional(element) || /^(?=[^a-z\n]*[a-z])(?=[^A-Z\n]*[A-Z])(?=[^\d\n]*\d)(?=[^`~!@#$%^&*()\-_=+|.<,>\/?;:[{\]}ç£¦¢öüä\n]*[`~!@#$%^&*()\-_=+|.<,>\/?;:[{\]}ç£¦¢öüä]).{8,}$/i.test(value);
					}, _getLabel('txtValidationInvalidPassword'));

					$this.find('input[name="password"]').on('keyup', function()
					{
						var $control = $(this);
						var value = $control.val();
						var length = value.length;

						if (typeof($control.data('dynamic')) !== 'undefined' && $control.data('dynamic') == true)
						{
							if (length)
							{
								$control.prev('label').addClass('required');

								$control.rules('add', {
									required: true,
								});
							}
							else
							{
								$control.prev('label').removeClass('required');

								$control.rules('remove', 'required');
							}

							$this.validate();
						}

						_checkPasswordStrength('.password-strength > .progress > .progress-bar', length, value);
					});
				}

				if ($this.find('input.inputmask').length)
				{
					$.validator.addMethod("inputmasked", function(value, element)
					{
						return this.optional(element) || (/\d{2} \d{3} \d{3} \d{3}/i.test(value) && /\d{11}/i.test(value.replaceAll(' ', '')));
					}, _getLabel('txtValidationInvalidCode'));
				}

				$this.validate(
				{
					ignore: '.ignore, :hidden, :disabled',
					highlight: function(element, errorClass)
					{
						if ($(element).hasClass('type-zone'))
						{
							$(element).parent('.tagging').removeClass('is-valid').addClass('is-invalid');
						}
						else
						{
							$(element).removeClass('is-valid').addClass('is-invalid');
						}
					},
					unhighlight: function(element, errorClass)
					{
						if ($(element).hasClass('type-zone'))
						{
							$(element).parent('.tagging').removeClass('is-invalid').addClass('is-valid');
						}
						else
						{
							$(element).removeClass('is-invalid').addClass('is-valid');
						}

						if ($(element).closest('.form-group').find('div.invalid-tooltip').length)
						{
							$(element).closest('.form-group').find('div.invalid-tooltip').remove();
						}
					},
					errorClass: 'invalid-tooltip right',
					errorElement: 'div',
					errorPlacement: function(error, element)
					{
						if (element.parent('.input-group').length)
						{
							element.parent('.input-group').next('div.invalid-tooltip').remove();
							error.insertAfter(element.parent());
						}
						else if (element.parent('.tagging').length)
						{
							element.parent('.tagging').next('div.invalid-tooltip').remove();
							error.insertAfter(element.parent());
						}
						else if (element.parents('.checkbox').length)
						{
							element.parents('.checkbox').next('div.invalid-tooltip').remove();
							error.insertAfter(element.parents('.checkbox'));
						}
						else
						{
							element.next('div.invalid-tooltip').remove();
							error.insertAfter(element);
						}
					}
				});
			});

			/*var validation = Array.prototype.filter.call(forms, function (form)
			{
				form.on('submit', function(ev)
				{
					if ($(this).checkValidity() === false)
					{
						ev.preventDefault();
						ev.stopPropagation();
					}

					$(this).toggleClass('was-validated', (!$(this).hasClass('was-validated')));
				});
				//form.addEventListener('submit', function (event) {
				//	if (form.checkValidity() === false) {
				//		event.preventDefault();
				//		event.stopPropagation();
				//	}
				//	form.classList.add('was-validated');
				//}, false);
			});*/
		},
		handleCountdown: function(selector, reset)
		{
			if (!$(selector).length)
			{
				return;
			}

			$(selector).each(function()
			{
				var $this = $(this);
				var time_elapsed = (typeof($this.data('elapsed')) !== 'undefined' && $this.data('elapsed') === true);
				var time_tick = (typeof($this.data('tick')) !== 'undefined' && $this.data('tick') === true);
				var route = '';

				if (typeof($this.parents('form').data('route')) !== 'undefined' && $this.parents('form').data('route').length)
				{
					route = $this.parents('form').data('route');
				}

				if ($this.hasClass('.static_timer') && typeof(reset) !== 'undefined' && reset === true)
				{
					$this.removeClass('.static_timer');
				}

				$this.countDown(
				{
					with_labels: false,
				});

				if (time_elapsed)
				{
					$this.on('time.elapsed', function()
					{
						$this.addClass('danger');

						if (route.length)
						{
							_clearWorkerCache();

							window.location.replace(route);
						}
					});
				}

				if (time_tick)
				{
					$this.on('time.tick', function(ev, ms)
					{
						var hours = moment.duration(ms).hours();
						var minutes = moment.duration(ms).minutes();
						var seconds = moment.duration(ms).seconds();

						if (hours == 0 && minutes == 5 && seconds == 0)
						{
							$this.addClass('warning');

							if (route.length)
							{
								var settings = {
									"timeOut": 0,
									"progressBar": true
								};

								App.showToastr('warning', _getLabel('txtExpireSoonMessage'), _getLabel('txtWarningTitle'), settings);
							}
						}
					});
				}
			});
		},
		handlePreselectedRegion: function(selector)
		{
			if (!$(selector).length)
			{
				return;
			}

			_triggerSync('#' + $(selector).attr('id'));
		},
		handleSynchronizableControls: function(selector)
		{
			if (!$(selector).length)
			{
				return;
			}

			$(selector).each(function()
			{
				var $this = $(this);
				var tag = $this.prop('tagName').toLowerCase();
				var type = ((tag === 'input' && typeof($this.attr('type')) !== 'undefined') ? $this.attr('type') : '');
				var css_class = $this.prop('class');

				//console.log(tag);
				//console.log(type);
				//console.log(css_class);

				if (tag === 'input' || tag === 'button')
				{
					switch (type)
					{
						case 'text':
							/*if ($this.hasClass('typeahead'))
							{
								return;
							}*/

							if (typeof($this.data('trigger-control')) !== 'undefined' && $($this.data('trigger-control')).length)
							{
								$this.on('keyup', function()
								{
									$($this.data('trigger-control')).val($this.val());
								});
							}

							$this.on('blur', function(ev)
							{
								if (!$this.prop('readonly'))
								{
									if ($this.hasClass('typeahead') && $('#' + $this.attr('id') + '_selected').length)
									{
										_triggerSync('#' + $this.attr('id') + '_selected');
									}
									else
									{
										_triggerSync('#' + $this.attr('id'));
									}
								}
							});

							break;
						case 'radio':
							$this.parent('label').on('click', function(ev)
							{
								if (!$this.prop('readonly'))
								{
									var proceed = true;

									//console.log('Is checked: ' + $this.is(':checked'));

									if ($this.attr('name') === 'validity_id' && $this.is(':checked'))
									{
										proceed = false;

										//console.log('Perform validation check');

										var validate = _validateSection($this);

										proceed = (validate.valid);
									}

									if (proceed)
									{
										_triggerSync('#' + $this.attr('id'));
									}
								}
							});

							break;
						case 'checkbox':
							var $next;

							if ($this.parent('label').hasClass('focusnext'))
							{
								$next = $this.next('input[type="text"]');
							}

							$this.parent('label').on('click', function(ev)
							{
								ev.stopPropagation();
								ev.preventDefault();

								if (!$this.prop('readonly'))
								{
									if ($this.is(':checked'))
									{
										$this.prop('checked', false);
									}
									else
									{
										$this.prop('checked', true);
									}

									_triggerSync('#' + $this.attr('id'));

									if ($next && $next.length)
									{
										if ($this.is(':checked'))
										{
											$next.attr('readonly', false).trigger('focus');
										}
										else
										{
											$next.attr('readonly', true).val('');
										}
									}
								}
							});

							break;
						case 'submit':
						case 'button':
							break;
					}
				}
				else if (tag === 'textarea')
				{
					if (typeof($this.data('trigger-control')) !== 'undefined' && $($this.data('trigger-control')).length)
					{
						$this.on('keyup', function()
						{
							$($this.data('trigger-control')).val($this.val());
						});
					}

					$this.on('blur', function(ev)
					{
						if (!$this.prop('readonly'))
						{
							_triggerSync('#' + $this.attr('id'));
						}
					});
				}
				else if (tag === 'select')
				{
					$this.on('change', function(ev)
					{
						if (!$this.prop('readonly'))
						{
							_triggerSync('#' + $this.attr('id'));

							_changeTypeaheadFilters('.typeahead', $this.val());
						}
					});
				}
				else if (tag === 'div')
				{
					if ($this.find('input[type="text"]').length)
					{
						$this.find('input[type="text"]').on('blur', function(ev)
						{
							if (!$(this).prop('readonly'))
							{
								_triggerSync('#' + $(this).attr('id'));
							}
						});
					}
				}
			});
		},
		handleActionButton: function(selector)
		{
			if (!$(selector).length)
			{
				return;
			}

			$(selector).each(function()
			{
				var $this = $(this);
				var field = $this.data('action');
				var $form = $this.parents('form');
				var route = $form.data('route');
				var controls;

				var proceed = true;

				if (typeof($this.data('route')) !== 'undefined' && $this.data('route').length)
				{
					route = $this.data('route');
				}

				if (typeof($this.data('check-control')) !== 'undefined')
				{
					controls = $this.data('check-control').split(',');
				}

				$this.on('click', function(ev)
				{
					ev.preventDefault();

					proceed = true;

					if (_is_offline)
					{
						proceed = false;

						Swal.fire(
						{
							icon: 'warning',
							title: _getLabel('txtWarningTitle'),
							text: _getLabel('txtOfflineRestrictionMessage'),
							confirmButtonText: _getLabel('btnOK'),
							buttonsStyling: false,
							customClass: {
								confirmButton: 'btn btn-warning btn-lg',
							},
							onBeforeOpen: _attachCSPCode,
						});
					}

					// Offline state
					if (!proceed)
					{
						return;
					}

					var verify;

					if (field === 'is_finished' || field === 'is_completed')
					{
						verify = _validateSection($('#name_1'));

						if (!verify.valid)
						{
							proceed = false;

							Swal.fire(
							{
								icon: 'error',
								title: _getLabel('txtErrorTitle'),
								text: _getLabel('txtValidationFailedMessage'),
								confirmButtonText: _getLabel('btnOK'),
								buttonsStyling: false,
								customClass: {
									confirmButton: 'btn btn-danger btn-lg',
								},
								onBeforeOpen: _attachCSPCode,
							}).then((result) => {
								if (result.isConfirmed)
								{
									if (verify.element.length)
									{
										if (verify.element.indexOf('_selected') !== false)
										{
											verify.element = verify.element.replace('_selected', '');
										}

										$(verify.element).trigger('focus');

										App.scrollTo(verify.element, 140, 500);
									}
								}
							});
						}
					}

					// First validation process failed
					if (!proceed)
					{
						return;
					}

					if (field === 'is_finished' || field === 'is_completed' || field === 'is_approved')
					{
						verify = _checkDateTimeFlow($this);

						if (!verify.valid)
						{
							proceed = false;

							Swal.fire(
							{
								icon: 'error',
								title: _getLabel('txtErrorTitle'),
								text: _getLabel('txtValidationInvalidConsistency'),
								confirmButtonText: _getLabel('btnOK'),
								buttonsStyling: false,
								customClass: {
									confirmButton: 'btn btn-danger btn-lg',
								},
								onBeforeOpen: _attachCSPCode,
							}).then((result) => {
								if (result.isConfirmed)
								{
									if (verify.element.length)
									{
										$(verify.element).triggerHandler('focus');

										App.scrollTo(verify.element, 140, 500);
									}
								}
							});
						}
					}

					// First verification process failed
					if (!proceed)
					{
						return;
					}

					if (field === 'is_aborted')
					{
						if (controls && controls.length)
						{
							$.each(controls, function(index, control)
							{
								if ($(control).length && !$(control).val().length)
								{
									$(control).trigger('focus');

									App.scrollTo(control, 140, 500);

									proceed = false;

									return false;
								}
							});
						}
					}
					else if (field !== 'request')
					{
						verify = _validateSection($this);

						if (!verify.valid)
						{
							proceed = false;

							Swal.fire(
							{
								icon: 'error',
								title: _getLabel('txtErrorTitle'),
								text: _getLabel('txtValidationFailedMessage'),
								confirmButtonText: _getLabel('btnOK'),
								buttonsStyling: false,
								customClass: {
									confirmButton: 'btn btn-danger btn-lg',
								},
								onBeforeOpen: _attachCSPCode,
							}).then((result) => {
								if (result.isConfirmed)
								{
									if (verify.element.length)
									{
										if (verify.element.indexOf('_selected') !== false)
										{
											verify.element = verify.element.replace('_selected', '');
										}

										$(verify.element).trigger('focus');

										App.scrollTo(verify.element, 140, 500);
									}
								}
							});
						}
					}

					// Second validation process failed
					if (!proceed)
					{
						return;
					}

					Swal.fire(
					{
						title: _getLabel('txtConfirmProceed'),
						showCancelButton: true,
						confirmButtonText: _getLabel('btnYes'),
						cancelButtonText: _getLabel('btnNo'),
						buttonsStyling: false,
						customClass: {
							confirmButton: 'btn btn-success btn-lg',
							cancelButton: 'btn btn-danger btn-lg ml-3',
						},
						onBeforeOpen: _attachCSPCode,
					}).then((result) => {
						if (result.isConfirmed)
						{
							_ajaxSetup();

							var id = 0;
							var action = 'sync';

							if ($('#record_id').length)
							{
								id = parseInt($('#record_id').val());
							}

							if (id === 0 || !route || !route.length)
							{
								return false;
							}

							if (field === 'request')
							{
								action = 'send';
							}

							_runUpdate({
								'element': $this,
								'route': route,
								'action': action,
								'id': id,
								'field': field,
								'value': 1,
							});
						}
					});
				});
			});
		},
		handleOpenLink: function(selector)
		{
			if (!$(selector).length)
			{
				return;
			}

			//console.log('Has link to open?!');

			$(selector).each(function()
			{
				var $this = $(this);

				var url = '';
				var files = [];
				var files_config = [];

				if (typeof($this.data('url')) !== 'undefined' && $this.data('url').length)
				{
					url = $this.data('url');
				}

				if (typeof($this.data('files')) !== 'undefined' && $this.data('files').length)
				{
					files = $this.data('files');
				}

				if (typeof($this.data('files-config')) !== 'undefined' && $this.data('files-config').length)
				{
					files_config = $this.data('files-config');
				}

				$this.on('click', function(ev)
				{
					ev.preventDefault();

					if (!$.isEmptyObject(files) && !$.isEmptyObject(files_config))
					{
						$.each(files_config, function(index, data)
						{
							if (data.type === 'pdf')
							{
								_triggerWindowOpen(files[index]);
							}
						});
					}

					window.location = url;
				});
			});
		},
		handleAddAdditionalRow: function(selector)
		{
			if (!$(selector).length)
			{
				return;
			}

			$(selector).on('click', function()
			{
				var $this = $(this);
				var $section = $this.parents('[id]');
				var total = ($section.children('.row').length);
				var $row = $section.children('.row').last();
				var rows = {};

				if (!$section.length)
				{
					return;
				}

				var $new = $row.clone();

				$new.html($new.html().replace(/\[([0-9]+)\]\"/ig, '[' + total + ']"'));

				$new.appendTo($section);

				_toggleRemoveAdditionalButton('.btn-remove-row');
			});
		},
		handleRemoveAdditionalRow: function(selector)
		{
			if (!$(selector).length)
			{
				return;
			}

			$(selector).on('click', function()
			{
				var $this = $(this);
				var $section = $this.parents('[id]');
				var $row = $section.children('.row').last();

				if (!$section.length)
				{
					return;
				}

				$row.remove();

				_toggleRemoveAdditionalButton('.btn-remove-row');
			});
		},
		handleAutofillable: function(selector)
		{
			if (!$(selector).length)
			{
				return;
			}

			$(selector).each(function()
			{
				var $this = $(this);

				$this.on('keyup blur', function()
				{
					if ($this.data('autofilled').length && $($this.data('autofilled')).length)
					{
						$($this.data('autofilled')).each(function()
						{
							var $control = $(this);

							$control.html($this.val());

							if (typeof($control.data('suffix')) !== 'undefined' && $control.data('suffix').length)
							{
								$control.html($control.html() + $control.data('suffix'));
							}
						});
					}
				});
			});
		},
		handleFiltersState: function(selector)
		{
			if (!$(selector).length)
			{
				return;
			}

			var $select = $(selector).find('select.multiselect[data-role="multiselect"]');
			var $multiselect = $select.parent('.multiselect-native-select');

			if (!$multiselect)
			{
				return;
			}

			$multiselect.find('button.multiselect-option').each(function()
			{
				var $this = $(this);

				$this.on('click', function()
				{
					if ($this.is(':last-child'))
					{
						if (!$this.hasClass('active'))
						{
							$select.children('option').not(':last-child').removeAttr('selected').prop('selected', false);
							$select.multiselect('refresh');
						}
					}
					else
					{
						$select.children('option:last-child').removeAttr('selected').prop('selected', false);
						$select.multiselect('refresh');
					}
				});
			});
		},
		redrawDatatables: function(selector)
		{
			if (!$(selector).length)
			{
				return;
			}

			$(selector).DataTable().ajax.reload();
		},
		showToastr: function(type, text, title, settings)
		{
			var kind = 'info';

			switch (type)
			{
				case 1:
				case 'success':
					kind = 'success';

					break;
				case 2:
				case 'error':
				case 'danger':
					kind = 'error';

					break;
				case 3:
				case 'warning':
					kind = 'warning';

					break;
			}

			var options = {
				"closeButton": false,
				"debug": false,
				"newestOnTop": false,
				"progressBar": true,
				"positionClass": "toast-top-right",
				"preventDuplicates": false,
				"onclick": null,
				"showDuration": "300",
				"hideDuration": "1000",
				"timeOut": "5000",
				"extendedTimeOut": "1000",
				"showEasing": "swing",
				"hideEasing": "linear",
				"showMethod": "fadeIn",
				"hideMethod": "fadeOut",
			};

			if (typeof(settings) === 'object')
			{
				$.extend(options, settings);
			}

			toastr.options = options;

			if (typeof(title) !== 'undefined' && title.length)
			{
				toastr[kind](text, title);
			}
			else
			{
				toastr[kind](text);
			}
		},
		showAlert: function(type, title, text, settings, functions)
		{
			var kind = 'info';

			switch (type)
			{
				case 1:
				case 'success':
					kind = 'success';

					break;
				case 2:
				case 'error':
				case 'danger':
					kind = 'error';

					break;
				case 3:
				case 'warning':
					kind = 'warning';

					break;
				case 4:
				case 'question':
					kind = 'question';

					break;
			}

			var options = {
				title: title,
				text: text,
				type: kind,
			};

			if (typeof(settings) === 'object')
			{
				$.extend(options, settings);
			}

			if (typeof(functions) === 'object')
			{
				Swal(options).then(
					function() // On confirm
					{
						if (typeof(functions.confirm) === 'function')
						{
							functions.confirm(this);
						}
					},
					function(dismiss) // On cancel/dismiss (dismiss can be 'cancel', 'overlay', 'close' and 'timer')
					{
						if (typeof(functions.cancel) === 'function')
						{
							functions.cancel(this, dismiss);
						}
					}
				);

			}
			else if (typeof(options.timer) !== 'undefined' && parseInt(options.timer) > 0)
			{
				Swal(options).then(
					function() // On confirm
					{},
					function(dismiss) // On cancel/dismiss (dismiss can be 'cancel', 'overlay', 'close' and 'timer')
					{}
				);
			}
			else
			{
				Swal(options);
			}
		},
	}
})(jQuery);

$(document).ready(function()
{
	"use strict";

	// Reusable utilities
	window.gullUtils = {
		isMobile: function isMobile()
		{
			return window && window.matchMedia("(max-width: 1024px)").matches;
		},
		changeCssLink: function(storageKey, fileUrl)
		{
			localStorage.setItem(storageKey, fileUrl);
			location.reload();
		}
	};

	var $sidebarToggle = $(".menu-toggle");
	var $sidebarLeft = $(".sidebar-left");
	var $sidebarLeftSecondary = $(".sidebar-left-secondary");
	var $sidebarOverlay = $(".sidebar-overlay");
	var $mainContentWrap = $(".main-content-wrap");
	var $sideNavItem = $(".nav-item");
	var $mobileMenu = $(".mobile-menu-icon");

	App.init();

	function sidebarMobileOpen()
	{
		$mobileMenu
	}

	function openSidebar()
	{
		$sidebarLeft.addClass("open");
		$mainContentWrap.addClass("sidenav-open");
	}

	function closeSidebar()
	{
		$sidebarLeft.removeClass("open");
		$mainContentWrap.removeClass("sidenav-open");
	}

	function openSidebarSecondary()
	{
		$sidebarLeftSecondary.addClass("open");
		$sidebarOverlay.addClass("open");
	}

	function closeSidebarSecondary()
	{
		$sidebarLeftSecondary.removeClass("open");
		$sidebarOverlay.removeClass("open");
	}

	function openSidebarOverlay()
	{
		$sidebarOverlay.addClass("open");
	}

	function closeSidebarOverlay()
	{
		$sidebarOverlay.removeClass("open");
	}

	function navItemToggleActive($activeItem)
	{
		var $navItem = $(".nav-item");

		$navItem.removeClass("active");
		$activeItem.addClass("active");
	}

	function toggleMenuTrigger(redraw)
	{
		if ($sidebarLeft.hasClass("open") || $sidebarLeftSecondary.hasClass("open"))
		{
			$sidebarToggle.addClass('open');
		}
		else
		{
			$sidebarToggle.removeClass('open');
		}

		if (typeof(redraw) !== 'undefined' && redraw === true)
		{
			App.redrawDatatables('#datatable');
		}
	}

	function initLayout()
	{
		let redraw = false;

		// Makes secondary menu selected on page load
		$sideNavItem.each(function(index)
		{
			let $item = $(this);

			if ($item.hasClass("active"))
			{
				let dataItem = $item.data("item");

				$sidebarLeftSecondary.find(`[data-parent="${dataItem}"]`).show();
			}
		});

		if (gullUtils.isMobile())
		{
			closeSidebar();
			closeSidebarSecondary();

			redraw = true;
		}

		toggleMenuTrigger(redraw);
	}

	$(window).on("resize", function(event)
	{
		let redraw = false;

		if (gullUtils.isMobile())
		{
			closeSidebar();
			closeSidebarSecondary();

			redraw = true;
		}

		toggleMenuTrigger(redraw);
	});

	initLayout();

	// Show Secondary menu area on hover on side menu item;
	$sidebarLeft.find(".nav-item").on("mouseenter", function(event)
	{
		let $navItem = $(event.currentTarget);
		let dataItem = $navItem.data("item");

		if (dataItem)
		{
			navItemToggleActive($navItem);
			openSidebarSecondary();
		}
		else
		{
			closeSidebarSecondary();
		}

		$sidebarLeftSecondary.find(".childNav").hide();
		$sidebarLeftSecondary.find(`[data-parent="${dataItem}"]`).show();

		toggleMenuTrigger();
	});

	// Prevent opeing link if has data-item
	$sidebarLeft.find(".nav-item").on("click", function(event)
	{
		let $navItem = $(event.currentTarget);
		let dataItem = $navItem.data("item");

		if (dataItem)
		{
			event.preventDefault();
		}
	});

	// Hide secondary menu on click on overlay
	$sidebarOverlay.on("click", function(event)
	{
		let redraw = false;

		if (gullUtils.isMobile())
		{
			closeSidebar();

			redraw = true;
		}

		closeSidebarSecondary();

		toggleMenuTrigger(redraw);
	});


	// Toggle menus on click on header toggle icon
	$sidebarToggle.on("click", function(event)
	{
		let isSidebarOpen = $sidebarLeft.hasClass("open");
		let isSidebarSecOpen = $sidebarLeftSecondary.hasClass("open");
		let dataItem = $(".nav-item.active").data("item");
		let redraw = false;

		if (isSidebarOpen && isSidebarSecOpen)
		{
			if (gullUtils.isMobile())
			{
				closeSidebar();

				redraw = true;
			}

			closeSidebarSecondary();
		}
		else if (isSidebarOpen)
		{
			closeSidebar();

			redraw = true;
		}
		else if (!isSidebarOpen && !isSidebarSecOpen && !dataItem)
		{
			openSidebar();

			redraw = true;
		}
		else if (!isSidebarOpen && !isSidebarSecOpen)
		{
			openSidebar();
			openSidebarSecondary();

			redraw = true
		}

		toggleMenuTrigger(redraw);
	});

	var $searchInput = $(".search-bar input");
	var $searchCloseBtn = $(".search-close");

	// Search toggle
	var $searchUI = $(".search-ui");

	$searchInput.on("focus", function()
	{
		$searchUI.addClass("open");
	});

	$searchCloseBtn.on("click", function()
	{
		$searchUI.removeClass("open");
	});

	// Secondary sidebar dropdown menu
	var $dropdown = $(".dropdown-sidemenu");
	var $subMenu = $(".submenu");

	$dropdown.find("> a").on("click", function(e)
	{
		e.preventDefault();
		e.stopPropagation();

		var $parent = $(this).parent(".dropdown-sidemenu");

		$dropdown.not($parent).removeClass("open");

		$(this).parent(".dropdown-sidemenu").toggleClass("open");
	});

	// Full screen
	function cancelFullScreen(el)
	{
		var requestMethod =
			el.cancelFullScreen ||
			el.webkitCancelFullScreen ||
			el.mozCancelFullScreen ||
			el.exitFullscreen;

		if (requestMethod)
		{
			// cancel full screen.
			requestMethod.call(el);
		}
		else if (typeof window.ActiveXObject !== "undefined")
		{
			// Older IE.
			var wscript = new ActiveXObject("WScript.Shell");

			if (wscript !== null)
			{
				wscript.SendKeys("{F11}");
			}
		}
	}

	function requestFullScreen(el)
	{
		// Supports most browsers and their versions.
		var requestMethod =
			el.requestFullScreen ||
			el.webkitRequestFullScreen ||
			el.mozRequestFullScreen ||
			el.msRequestFullscreen;

		if (requestMethod)
		{
			// Native full screen.
			requestMethod.call(el);
		}
		else if (typeof window.ActiveXObject !== "undefined")
		{
			// Older IE.
			var wscript = new ActiveXObject("WScript.Shell");

			if (wscript !== null)
			{
				wscript.SendKeys("{F11}");
			}
		}

		return false;
	}

	function toggleFullscreen()
	{
		var elem = document.body;
		var isInFullScreen =
			(document.fullScreenElement && document.fullScreenElement !== null) ||
			(document.mozFullScreen || document.webkitIsFullScreen);

		if (isInFullScreen)
		{
			cancelFullScreen(document);
		}
		else
		{
			requestFullScreen(elem);
		}

		return false;
	}

	$("[data-fullscreen]").on("click", toggleFullscreen);
});

// PreLoader
// $(window).load(function()
// {
// 	$('#preloader').fadeOut('slow', function()
// 	{
// 		$(this).remove();
// 	});
// });

// makes sure the whole site is loaded
$(window).on("load", function()
{
	// will first fade out the loading animation
	jQuery("#loader").fadeOut();
	// will fade out the whole DIV that covers the website.
	jQuery("#preloader").delay(500).fadeOut("slow");
});
